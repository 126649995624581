/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable array-callback-return */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import Table from '../../../../components/organisms/Table';
import { thData } from './TableEntries';
import { GET_DOSSIER, UPDATE_DOSSIER, UPLOAD_DOSSIER } from '../../../../constants/actionType';
import './TableDossier.css';

/**
 *
 * @returns {React.ReactElement} - returns the IDA input screen
 */
const DossierTable = (props) => {
  const { Activity, jsonData, onCancel, isJson, setIsJson } = props;
  console.log(jsonData, 'jsonDatamm');
  const { getDossier } = useSelector((state) => state.dossier);
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch({ type: GET_DOSSIER });
  }, [dispatch]);
  console.log(getDossier, 'sdfasdf');
  /**
   * @param key
   */
  const onSendUpdatedValue = React.useCallback(

    async (key, rowData) => {
      const arr = [];
      if (jsonData?.length > 0) {
        jsonData?.map((item) => {
          arr.push({
            state: item?.State,
            district: item?.District,
            date_planned: moment(item["Date Planned"], 'MM/DD/YYYY').format('YYYY-MM-DD') || '',
            date_completed: moment(item["Date Completed"], 'MM/DD/YYYY').format('YYYY-MM-DD') || '',
            certification_completed: item?.["Certification completed"],
          });
        });
        dispatch({ type: UPLOAD_DOSSIER, payload: arr });
      } else {
        await dispatch({ type: UPDATE_DOSSIER, payload: rowData });

        setTimeout(() => {
          dispatch({
            type: GET_DOSSIER  });
        }, 5000);
      }
      setIsJson(false);
    },
    [dispatch, jsonData, setIsJson],
  );
  return (
    <Table
      header={thData}
      rows={isJson ? jsonData : getDossier}
      slideData
      inputTable
      onSendUpdatedData={(key, rowData) => onSendUpdatedValue(key, rowData)}
      hideKeyFields={['state_id', 'dossier_id', 'district_id', 'block']}
      showSno
      onCancel={onCancel}
      getById="dossier_id"
      Activity={Activity}
      uniqueKey="dossier_id"
      IsExcelData={isJson}
      screen="input"
    />
  );
};

export default DossierTable;
DossierTable.propTypes = {
  Activity: PropTypes.string,
};
DossierTable.defaultProps = {
  Activity: '',
};
