export const thDatas = [
    'S.No',
    'State',
    'District',
    // 'Year',
    // 'Drug Regime',
    // 'Drug Type',
    // 'From Date',
    // 'To Date',
    // 'Status',
    'Action',
  ];
  
  export const tdDatas = [
    {
      S_No: '1',
      State: 'TamilNadu',
      District: 'kuaala',
      DrugType: 'DA',
      DrugReigme: 'IDA',
      FromDate: '09 Mar 2022',
      ToDate: '09 Mar 2021',
      Status: 'Active',
    },
    {
      S_No: '2',
      State: 'Goa',
      District: 'North Goa',
      DrugType: 'DEC',
      DrugReigme: 'IDA/DA',
      FromDate: '09 Mar 2021',
      ToDate: '09 Mar 2021',
      Status: 'Active',
    },
  ];
  