/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import './Table.css';
import PropTypes from 'prop-types';
import { thDatas } from './TableEntries';
import Table from '../../../../components/organisms/Table/index';
import Typography from '../../../../components/atoms/Typography/index';
import { BULK_UPLOAD_DRUG, GET_DRUG, GET_STATE } from '../../../../constants/actionType';
/**
 * @param {object} props -  required props in input table
 * @name InputTable
 * @returns {React.ReactElement} return the user role component
 */
const InputTable = (props) => {
  const { onEditClick, isJson, jsonData, setIsJson } = props;
  const dispatch = useDispatch();
  const { getDrugType } = useSelector((state) => state?.drugTypeDetails);
  const getStatevalue  = useSelector((state) => state?.stateDetails?.getStateValue);
  const [mappedRowData, setMappedRowData] = useState([]);
  const [rowIds, setRowIds] = useState([]);
  
  useEffect(() => {
    // dispatch({ type: GET_DRUG });
    dispatch({ type: GET_STATE });
  }, [dispatch]);
  /**
   *
   */
  const onSendUpdatedData = () => {
    const arr = [];
    jsonData?.map((item) => {
      arr.push({
        state: item?.State,
        district: item?.District,
        drug_regime: item?.Drug_Reigme,
        drug_type: item?.Drug_Type,
        from_date: item?.From_Date,
        to_date: item?.To_Date,
        active_status: item?.Status,
      });
    });
    dispatch({ type: BULK_UPLOAD_DRUG, payload: arr });
    setIsJson(false);
  };

  React.useEffect(() => {
    if (getStatevalue?.length) {
      const tmpArr = [];
      const tmpIdsArr = [];
      getStatevalue?.map((data, index) => {
        tmpIdsArr.push(data.state_id);
        return tmpArr.push({
          S_no: index + 1,
          State: data?.state,
          State_Id: data?.state_id,
          State_Code:data?.state_code,
          // Year:data?.year
          // District: data?.district,
          // District_Id: data?.district_id,
          // Drug_Reigme: data?.drug_regime,
          // Drug_Reigme_Id: data?.drug_regime_id,
          // Drug_Type: data?.drug_type,
          // Drug_Type_Id: data?.drug_type_id,
          // From_Date: data?.from_date,
          // To_Date: data?.to_date,
          // Status: data?.status,
        });
      });
      setRowIds(tmpIdsArr);
      setMappedRowData(tmpArr);
    }
  }, [getStatevalue]);

  return (
    <Grid>
      <Typography
        className="class"
        text="Create Program Activity"
        colorType="quinary"
        customClass="text"
        type="subHeading"
      />

      <Table
        onEditData={(e) => onEditClick(e, rowIds)}
        header={thDatas}
        rows={isJson ? jsonData : mappedRowData}
        masterSetup={!isJson && ['Downloader', 'Search']}
        userActivity="View State Master"
        optional
        edit
        hideKeyFields={['State_Id']}
        Activity="DrugType"
        inputTable={isJson}
        IsExcelData={isJson}
        onSendUpdatedData={onSendUpdatedData}
        screen="masters"
      />
    </Grid>
  );
};
export default InputTable;
InputTable.propTypes = {
  onEditClick: PropTypes.func.isRequired,
};


