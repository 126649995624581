/* eslint-disable no-unused-vars */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import BaseUrl from '../../config/config';
import {
  getStateProgressStatus,
  getStateProfile,
  getDistrictMdaProfile,
  getDistrictProfileWidget,
  getDistrictProfileWidgetGraph,
  getDistrictTasProfile,
  getDistrictDecisionMatrix,
  getStateProfileChart,
  getStateDataQuality,
  getDistrictMDABlocks,
} from '../../utils/api';

/**
 * @name getDataQualityRequest used for dashboard api call
 * @param {string} data data
 * @returns {Array} returns the axios output
 */
export const getStateProgressStatusRequest = (data) => {
  return axios({
    method: 'post',
    url: `${BaseUrl}/${getStateProgressStatus}`,
    data: {
      years: data?.Year || '',
      state: data?.State || '',
      district: data?.District || '',
      activity: data?.Activity || '',
      sort: data.Sort,
    },
  });
  // return axios.get(`${BaseUrl}/${getStateProgressStatus}?sort=${data.Sort}`);
};

/**
 * @name getDataQualityRequest used for dashboard api call
 * @param {string} data data
 * @returns {Array} returns the axios output
 */
export const getStateProfileRequest = (data) => {
  return axios({
    method: 'post',
    url: `${BaseUrl}/${getStateProfile}`,
    data: {
      years: data?.Year || '',
      state: data?.State || '',
      district: data?.District || '',
      activity: data?.Activity || '',
      sort: data.Sort,
      strategy: data.Strategy || '',
    },
  });
};

/**
 * @name getDistrictMdaProfileRequest used for dashboard api call
 * @param {number} data payload data
 * @returns {Array} returns the axios output
 */
export const getDistrictMdaProfileRequest = (data) => {
  return axios({
    method: 'post',
    url: `${BaseUrl}/${getDistrictMdaProfile}`,
    data: {
      state: data.State || data.state || '',
      activity: data.Activity || '',
      status: data.Status || '',
      sort: data.Sort || '',
      district: data.District || data.district || '',
      block: data?.Site || '',
      year: data.Year || '',
      strategy: data.Strategy || data.strategy || '',
    },
  });
};

/**
 * @name getDistrictProfileWidgetRequest used for dashboard api call
 * @param {number} data payload data
 * @returns {Array} returns the axios output
 */
export const getDistrictProfileWidgetRequest = (data) => {
  return axios({
    method: 'post',
    url: `${BaseUrl}/${getDistrictProfileWidget}`,
    data: {
      year: data.Year || '', // years filter removed
      state: data.State || '',
      district: data.District || '',
      sort: data.Sort || '',
      activity: data.Activity || '',
      strategy: data.Strategy || '',
    },
  });
};

/**
 * @name getDistrictProfileWidgetGraphRequest used for dashboard api call
 * @param {number} data payload data
 * @returns {Array} returns the axios output
 */
export const getDistrictProfileWidgetGraphRequest = (data) => {
  return axios({
    method: 'post',
    url: `${BaseUrl}/${getDistrictProfileWidgetGraph}`,
    data,
  });
};
/**
 * @name getDistrictTasProfileRequest used for dashboard api call
 * @param {number} data payload data
 * @returns {Array} returns the axios output
 */
export const getDistrictTasProfileRequest = (data) => {
  return axios({
    method: 'post',
    url: `${BaseUrl}/${getDistrictTasProfile}`,
    data: {
      year: data.Year || '',
      state: data.State || '',
      district: data.District || '',
      sort: data.Sort || '',
      activity: data.Activity || data.Activity_TAS || '',
      strategy: data.Strategy || '',
    },
  });
};
/**
 * @name getDistrictDecisionMatrixRequest used for dashboard api call
 * @param {number} data payload data
 * @returns {Array} returns the axios output
 */
export const getDistrictDecisionMatrixRequest = (data) => {
  return axios({
    method: 'post',
    url: `${BaseUrl}/${getDistrictDecisionMatrix}`,
    data: {
      year: data.Year || '',
      state: data.State || '',
      district: data.District || '',
      activity: data.Activity || '',
      strategy: data.Strategy || '',
      sort: data.Sort || '',
    },
  });
};
/**
 * @name getStateProfileChartRequest used for dashboard api call
 * @param {number} data payload data
 * @returns {Array} returns the axios output
 */
export const getStateProfileChartRequest = (data) => {
  return axios({
    method: 'post',
    url: `${BaseUrl}/${getStateProfileChart}`,
    data: {
      state: data.State || '',
      activity: data.Activity || '',
      years: '', // years filter removed
      district: data.District || '',
      strategy: data.Strategy || '',
    },
  });
};
/**
 * @name getDataQualityRequest used for dashboard api call
 * @param {number} data payload data
 * @returns {Array} returns the axios output
 */
export const getDataQualityRequest = (data) => {
  return axios({
    method: 'post',
    url: `${BaseUrl}/${getStateDataQuality}`,
    data: {
      year: data.Year || '',
      state: data.State || '',
      district: data.District || '',
      sort: data.Sort || '',
      activity: data.Activity || '',
      strategy: data.Strategy || '',
    },
  });
};

/**
 * @name getDistrictMDABlocksRequest used for dashboard api call
 * @param {number} data payload data
 * @returns {Array} returns the axios output
 */
export const getDistrictMDABlocksRequest = (data) => {
  return axios({
    method: 'post',
    url: `${BaseUrl}/${getDistrictMDABlocks}`,
    data: {
      district: data.District || '',
    },
  });
};
