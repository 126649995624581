/* eslint-disable global-require */
/* eslint-disable react/prop-types */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable dot-notation */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-lone-blocks */
/* eslint-disable consistent-return */
/* eslint-disable no-debugger */
/* eslint-disable react/no-unknown-property */
/* eslint-disable no-constant-condition */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Grid } from '@mui/material';
import _ from 'loadsh';
import CustomButton from '../../atoms/CustomButton';
import Pagination from './Pagination';
import './Table.css';
import CustomIcons from '../../../utils/icons';
import colors from '../../../utils/colors';
import EditBox from '../../atoms/Edit';
import SearchBar from '../../atoms/Search';
import FileFormat from '../../atoms/FileFormat';
import Typography from '../../atoms/Typography';
import { maxTime } from 'date-fns';

// import customImages from '../../../utils/images';
/**
 *
 * @param  {object} props - required props in table component
 * @returns {React.ReactElement} - returns the Table component
 */
const Table = (props) => {
  const {
    rows,
    onClickPageBtn,
    header,
    mode,
    onClickPerPage,
    currentPage,
    currentLimit,
    type,
    inputTable,
    masterSetup,
    userActivity,
    onViewData,
    onEditData,
    onDeleteData,
    optional,
    view,
    edit,
    deleteData,
    threshold,
    proved,
    onProveStatus,
    downloadData,
    hideKeyFields,
    showSno,
    onSendUpdatedData,
    downloadUrl,
    onCancel,
    rowClick,
    dashboardEdit,
    Activity,
    uniqueKey,
    IsExcelData,
    screen,
    // editableTableData,
  } = props;
  const [page, setPage] = React.useState(0);
  const [rowData, setRowData] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [isMove, setIsMove] = React.useState(null);
  const [editDataCellView, setEditDataCellView] = React.useState(false);
  const [tableDataEditAfter, setTableDataEditAfter] = React.useState(true);
  const [showSearch, setShowSearch] = React.useState(false);
  const [hideSearch, setHideSearch] = React.useState(true);
  const [upDatedData, setUpdatedData] = React.useState(false);
  const [editedIndices, setEditedIndex] = React.useState({});
  const [editValidation, setEditValidation] = React.useState(false);
  const [downloadValidation, setDownloadValidation] = React.useState(false);
  const [edits, setEdit] = React.useState();
  const [head, setHead] = React.useState(header);
  const [download, setDownload] = React.useState(false);
  const ValidationGroup = localStorage.getItem('userGroupId');
  const ValidationType = localStorage.getItem('userTypeId');
  const ValidationGroupId = localStorage.getItem('userGroupId');
  const ValidationTypeId = localStorage.getItem('userTypeId');
  const InputActionValidation = localStorage.getItem('ActionAccess');
  const DashBoard = 'DashBoard';
  const Actionvalue = JSON.parse(InputActionValidation);
  const { BulkUpload, Download, Edit, ManualDataInput, View } =
    Actionvalue?.[screen !== undefined ? screen : DashBoard]?.[0];

  /**
   *
   * @returns
   */

  React.useEffect(() => {
    if (
      (ValidationGroup === '4' && ValidationType === '1') ||
      (ValidationGroup === '7' && ValidationType === '1') ||
      (ValidationGroup === '8' && ValidationType === '5')
    ) {
      setEditValidation(true);
      setDownloadValidation(true);
    }
    if (
      (ValidationGroup === '5' && ValidationType === '2') ||
      (ValidationGroup === '4' && ValidationType === '3') ||
      (ValidationGroup === '0' && ValidationType === '4')
    ) {
      setDownloadValidation(false);
      setEditValidation(false);
    }
  }, [ValidationGroup, ValidationType]);

  useEffect(() => {
    if (ValidationGroupId === '5' && ValidationTypeId === '2') {
      setEdit(false);
      setDownload(false);
      setEditValidation(false);
    }
    if (ValidationGroupId === '4' && ValidationTypeId === '1') {
      setEdit(false);
      setDownload(true);
    }
    if (ValidationGroupId === '7' && ValidationTypeId === '1') {
      setEdit(false);
      setDownload(true);
    }
    if (ValidationGroupId === '8' && ValidationTypeId === '5') {
      setEdit(true);
      setDownload(true);
    }
    if (ValidationGroupId === '4' && ValidationTypeId === '3') {
      setEdit(false);
      setDownload(false);
    }
    if (ValidationGroupId === '0' && ValidationTypeId === '4') {
      setEdit(true);
      setDownload(true);
    }
  }, [ValidationTypeId, ValidationGroupId]);

  useEffect(() => {
    if (edits === false) {
      const value = _.remove(header, (e) => {
        return e === 'Action';
      });
      setHead(value);
    } else {
      setHead(header);
    }
  }, [header, download, edits]);
  React.useEffect(() => {
    if (IsExcelData) {
      setUpdatedData(true);
    }
  }, [IsExcelData]);

  /**
   * @name getColorType
   * @param {*} colorType -  background color
   * @returns {React.ReactElement} -  returns the background color
   */
  const getColorType = (colorType) => {
    switch (colorType) {
      case 1:
        return colors.blue.primary;
      case 2:
        return colors.orange.primary;
      case 3:
        return colors.yellow.primary;
      default:
        return colors.blue.primary;
    }
  };
  /**
   * @function descendingComparator used for descendingComparator action
   * @param {string} a input value
   * @param {string} b input value
   * @param {string} orderBy input value
   * @returns {string} value
   */
  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  /**
   * @function getComparator used for descendingComparator action
   * @param {string} order input value
   * @param {string} orderBy input value
   * @returns {string} value
   */
  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  /**
   * @function stableSort used for descendingComparator action
   * @param {string} array input value
   * @param {string} comparator input value
   * @returns {string} value
   */
  function stableSort(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  /**
   *
   * @param {*} eventValue - onchange event
   * @param {*} totalPages - totalPages value
   */
  const handleChangePage = (eventValue, totalPages) => {
    if (
      !isNaN(Number(eventValue)) &&
      eventValue !== '' &&
      eventValue.toString().trim() !== '' &&
      Number(eventValue) !== 0
    ) {
      if (totalPages) {
        if (totalPages >= Number(eventValue)) {
          setPage(eventValue - 1);
        }
      } else {
        setPage(eventValue - 1);
      }
    }
  };
  /**
   *
   * @param {*} eventValue - onchange event
   */
  const handleChangeRowsPerPage = (eventValue) => {
    // eslint-disable-next-line radix
    setRowsPerPage(parseInt(eventValue));
    setPage(0);
  };

  useEffect(() => {
    setRowData(rows || '');
    setPage(0);
  }, [rows]);

  /**
   *
   */
  const onTableDataInput = () => {
    setEditDataCellView(true);
    setTableDataEditAfter(false);
    setUpdatedData(true);
  };
  /**
   *
   * @param {*} e - event of onchange
   * @param {*} index - index of table data
   * @param {*} key - key value of table data
   */
  const handleDataEdit = (e, index, key) => {
    const rowID = rowsPerPage * (page + 1) - (rowsPerPage - (index + 1));
    rowData[rowID - 1][key] = e.target.value;
    setRowData(rowData);
    setEditedIndex({ ...editedIndices, [`${rowID - 1}`]: rowID - 1 });
  };

  /**
   *
   * @param {*} e - event of onchange
   * @param {*} index - index of table data
   * @param {*} key - key value of table data
   */
  const functionString = (e, index, key) => {
    const inputValue = e.target.value;
    if (!/[A-Za-z]+$/.test(inputValue)) {
      return;
    }
    handleDataEdit(e, index, key);
  };

  /**
   *
   * @param {string} key - passing key data
  //  * @param e
   */
  const onSubmitUpdatedData = (key) => {
    setEditDataCellView(false);
    setTableDataEditAfter(true);
    if (key === 'Update') {
      const EditedItems = Object.keys(editedIndices).map((item) => {
        return rowData[item];
      });

      onSendUpdatedData(key, EditedItems);
      setUpdatedData(false);
      setEditedIndex({});
    }
  };


  /**
   *
   * @param {*} arr
   * @returns
   */
  function checkIfDuplicateExists(arr) {
    return new Set(arr).size !== arr.length;
  }

  const NumberField = [
    'covered_population',
    'covered_population',
    'population',
    'population_under_eu0',
    'sample_size0',
    'number_of_persons_examined0',
    'number_of_persons_positive0',
    'number_of_invalid_test0',
    'critical_cut_off0',
    'eu_count',
    'population',
    // 'Test Type',
    // 'pre_tas_survey_date',
    // 'test_type',
  ];
  const DateField = [
    'NBS Survey Date',
    'NBS Survey Date',
    'drug_available_date_DEC',
    'drug_available_date_ALB',
    'drug_available_date_IVR',
    'nbs_survey_date',
    'nbs_survey_date',
    'date_proposed',
    'date_planned',
    'date_completed',
    // 'block_date_proposed0',
    'block_date_planned0',
    'block_date_completed0',
    'pre_tas_survey_date',
    'test_type_id',
    'NBS Survey Date',
    'ALB',
    'IVR',
    'DEC',
    'from_date',
    'to_date',
    'To_Date',
    'Form_Date',
  ];
  // eslint-disable-next-line global-require

  const res = moment('44603', 'DDMMYYYY').format('YYYY-MM-DD');
  const responce = moment(new Date((44603 - (25567 + 1)) * 86400 * 1000)).format('YYYY-MM-DD');
  const StringField = ['state', 'district', 'block_strategy'];

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < 10; i++) {
    NumberField.push(`BSE S${i}`);
    NumberField.push(`BSE R${i}`);
    NumberField.push(`Positive R${i}`);
    NumberField.push(`Positive S${i}`);
    StringField.push(`Block Name${i}`);
    DateField.push(`block_date_proposed${i}`);
    DateField.push(`block_date_planned${i}`);
    DateField.push(`block_date_completed${i}`);
    DateField.push(`NBS Survey Date${i}`);
    NumberField.push(`population${i}`);
    NumberField.push(`population_under_eu${i}`);
    NumberField.push(`sample_size${i}`);
    NumberField.push(`number_of_persons_examined${i}`);
    NumberField.push(`number_of_persons_positive${i}`);
    NumberField.push(`number_of_invalid_test${i}`);
    NumberField.push(`critical_cut_off${i}`);
  }
  /**
   * @param {event} e event object on changing search bar
   */
  const onSearch = (e) => {
    setPage(0);
    const filters = [];
    const val = 'MDA_IDA_id';
    const uniqueIds = {};
    if (e.target.value) {
      rows.forEach((item) => {
        const keys = Object.keys(item);
        let isMatchFound = false;  
        for (let i = 0; i < keys.length; i += 1) {
          if (
            item[keys[i]] &&
            !uniqueIds[item[uniqueKey]] &&
            item[keys[i]] !== null &&
            item[keys[i]].toString().toLowerCase().includes(e.target.value.toLowerCase())
          ) {
            isMatchFound = true;
            if (item[uniqueKey]) {
              uniqueIds[item[uniqueKey]] = 1;
            }
          }
        }
        if (isMatchFound) {
          filters.push(item);
        }
      });
      setRowData(filters);
    } else {
      setRowData(rows);
    }
  };
  /**
   *
   * @param {*} data table data
   * @returns {boolean} false when data contains only letters and whitespace
   */
  const isString = (data) => {
    return !/[a-zA-Z]/g.test(data);
  };
  return (
    <Grid>
      <Grid className="tableGrid">
        {masterSetup && (
          <Grid className="masterTable">
            <Grid className="activity-title">
              <Typography type="caption" text={userActivity} colorType="primary" customClass="tableTitle" />
            </Grid>
            <Grid className="actionField">
              {masterSetup &&
                masterSetup?.map((icon) =>
                  icon === 'Search' ? (
                    <SearchBar
                      handleChange={(e) => onSearch(e)}
                      handleClear={() => setRowData(rows)}
                      showInput={false}
                    />
                  ) : (
                    <Grid>
                      {/* {download && ( */}
                      {Download && (
                        <Grid className="fileFormat">
                          <FileFormat fileData={rows} title={Activity} />
                        </Grid>
                      )}
                    </Grid>
                  ),
                )}
            </Grid>
          </Grid>
        )}
        {inputTable && (
          <Grid className="actionField">
            {tableDataEditAfter && (
              <>
                <Grid sx={{ paddingRight: 2 }}>
                  <SearchBar handleChange={(e) => onSearch(e)} handleClear={() => setRowData(rows)} />
                </Grid>
                {Edit === 1 && (
                  <Grid className="editGrid">
                    <EditBox handleClick={onTableDataInput} />
                  </Grid>
                )}
              </>
            )}
            {Download === 1 && (
              <Grid className="fileFormat">
                <FileFormat fileData={rows} title={Activity} />
              </Grid>
            )}
          </Grid>
        )}

        <div className="DynamicTable">
          <table className={inputTable || masterSetup ? 'inputTables' : 'tables'}>
            <thead className="thead">
              {type === 'mdaCoverage' ? (
                <tr className="mdaHeader">
                  {header?.map((val, index) => {
                    return (
                      <th
                        colSpan={header.length === index + 1 ? 4 : ''}
                        className={header.length === index + 1 ? 'lastMdaHeader' : 'thData'}
                      >
                        {header.length === index + 1
                          ? val.data.map((item, id) => {
                              return (
                                <>
                                  {id === 0 && val.name}
                                  <th className="thData">{item}</th>
                                </>
                              );
                            })
                          : val.name}
                      </th>
                    );
                  })}
                </tr>
              ) : (
                <tr>
                  {header?.map((value) => {
                    return (
                      !hideKeyFields?.includes(value) && (
                        <th className={inputTable || masterSetup ? 'slideThData' : 'thData'}>{value}</th>
                      )
                    );
                  })}
                </tr>
              )}
            </thead>
            <tbody>
              {rowData?.length === 0 ? (
                <tr>
                  <td colSpan={`${(header?.length || 12) + 1}`}>
                    <center>
                      <img
                        style={{ width: '8rem', height: '7rem' }}
                        src={CustomIcons.NoDataFound}
                        alt="No Data Found"
                      />
                    </center>
                  </td>
                </tr>
              ) : mode === 'client' ? (
                stableSort(rowData, getComparator())
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((objValues, index) => {
                    // console.log(rowData,"pretasrowdata");
                    if (type === 'preTas') {
                      return (
                        <>
                          {objValues.data.map((item, id) => {
                            const keys = Object.keys(item);
                            if (id > 0) {
                              return (
                                <tr>
                                  {Object.values(item).map((val, i) => {
                                    const key = keys[i];
                                    return <td className={`${isString(val) && 'tdDataNumber'} tdData`}>{val}</td>;
                                  })}
                                </tr>
                              );
                            }
                            return (
                              <tr>
                                <td className="tdData" rowSpan={objValues.data.length}>
                                  {objValues.state}
                                </td>
                                {Object.values(objValues.data[0]).map((val) => {
                                  return (
                                    <td className={`${isString(val) && 'tdDataNumber'} monthBackground tdData`}>
                                      {val}
                                    </td>
                                  );
                                })}
                              </tr>
                            );
                          })}
                        </>
                      );
                    }
                    if (type === 'mdaCoverage') {
                      return (
                        <tr className="mdaData">
                          {Object.keys(objValues).map((obj, id) => {
                            if (Object.keys(objValues).length === id + 1) {
                              return (
                                <td colSpan={Object.keys(objValues).length === id + 1 ? 4 : ''} className="lastMdaData">
                                  {Object.keys(objValues.data).map((item) => {
                                    return (
                                      <td
                                        className={`${
                                          objValues.data && isString(objValues.data[item]) && 'tdDataNumber'
                                        } tdData`}
                                      >
                                        {objValues.data && objValues.data[item]}
                                      </td>
                                    );
                                  })}
                                </td>
                              );
                            }
                            return (
                              !hideKeyFields?.includes(obj) && (
                                <td className={`${objValues && isString(objValues[obj]) && 'tdDataNumber'} tdData`}>
                                  {objValues && objValues[obj]}
                                </td>
                              )
                            );
                          })}
                        </tr>
                      );
                    }
                    if (type === 'tasSummery') {
                      return (
                        <tr className="mdaData">
                          {Object.keys(objValues).map((obj, id) => {
                            if (Object.keys(objValues).length === id + 1) {
                              return (
                                <td colSpan={Object.keys(objValues).length === id + 1 ? 4 : ''} className="lastMdaData">
                                  {Object.keys(objValues.data).map((item) => {
                                    return (
                                      <td
                                        className={`${
                                          objValues.data && isString(objValues.data[item]) && 'tdDataNumber'
                                        } tdData`}
                                      >
                                        {objValues.data && objValues.data[item]}
                                      </td>
                                    );
                                  })}
                                </td>
                              );
                            }
                            return (
                              <td className={`${objValues && isString(objValues[obj]) && 'tdDataNumber'} tdData`}>
                                {objValues && objValues[obj]}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    }

                    return (
                      <tr onClick={() => rowClick && rowClick(objValues)} className={rowClick ? 'on-row-click' : ''}>
                        {Object.entries(
                          showSno
                            ? { id: rowsPerPage * (page + 1) - (rowsPerPage - (index + 1)), ...objValues }
                            : objValues,
                        ).map(([key, value]) => {
                          return (
                            !hideKeyFields?.includes(key) && (
                              <td className={`${isString(value) && 'tdDataNumber'} tdData`}>
                        
                                {(editDataCellView && key !== 'view') || dashboardEdit ? (
                                  <input
                                    type={
                                  
                                      (NumberField.includes(key) && 'number') ||
                                      (DateField.includes(key) && 'date') ||
                                      (StringField.includes(key) && 'string')
                                    }
                                    name="txt"
                                    disabled={key === 'id' || key === 'state' ||key === 'district' || key === 's_no'}
                                    value={
                                      DateField.includes(key)
                                        ? moment(new Date(value)).format('YYYY-MM-DD')
                                        : value || ''
                                    }
                                    className="dataCellEdit"
                                    onChange={(e) => {
                                      {
                                        
                                        StringField.includes(key)
                                          ? functionString(e, index, key)
                                          : handleDataEdit(e, index, key);
                                      }
                                    }}
                                  />
                                ) : screen === 'input' ? (
                                  
                                  DateField.includes(key) && Activity !== '' && value !== '' ? (
                                    moment(new Date((value - (25567 + 2)) * 86400 * 1000)).format('DD-MM-YYYY') !==
                                      'Invalid date' || '' ? (
                                      moment(new Date((value - (25567 + 2)) * 86400 * 1000)).format('DD-MM-YYYY')
                                    ) : (
                                      value
                                    )
                                  ) : (
                                    value
                                  )
                                ) : (
                                  value
                                )}
                              </td>
                            )
                          );
                        })}
                        {optional ? (
                          <td className="optionalIcons">
                            {view && edits && (
                              <img
                                src={CustomIcons.View}
                                alt="view"
                                onClick={() => onViewData(objValues)}
                                aria-hidden="true"
                                className="eyeView"
                              />
                            )}
                            {/* {edit && edits && ( */}
                            {Edit === 1 && (
                              <img
                                src={CustomIcons.Pencil}
                                className="editData"
                                alt="edit"
                                onClick={() => onEditData(objValues)}
                                aria-hidden="true"
                              />
                            )}
                            {deleteData && edits && (
                              <img
                                src={CustomIcons.Delete}
                                alt="delete"
                                onClick={() => onDeleteData(objValues)}
                                aria-hidden="true"
                                className="deleteData"
                              />
                            )}
                          </td>
                        ) : (
                          <td className="optionalIcons">
                            {threshold && (
                              <Grid>
                                {download && (
                                  <a href={downloadUrl} download className="downloadLink">
                                    <img
                                      src={CustomIcons.DownloadData}
                                      alt="download"
                                      onClick={downloadData}
                                      aria-hidden="true"
                                      className="createSpace"
                                    />
                                  </a>
                                )}
                              </Grid>
                            )}
                            {threshold && download && (
                              <img
                                src={CustomIcons.DoubleTick}
                                alt="proved"
                                onClick={onProveStatus}
                                aria-hidden="true"
                              />
                            )}
                          </td>
                        )}
                      </tr>
                    );
                  })
              ) : (
                rowData?.map((objValues) => {
                  if (type === 'preTas') {
                    return (
                      <>
                        {objValues.data.map((item, id) => {
                          if (id > 0) {
                            return (
                              <tr>
                                {Object.values(item).map((val) => {
                                  return <td className={`${isString(val) && 'tdDataNumber'} tdData`}>{val}</td>;
                                })}
                              </tr>
                            );
                          }
                          return (
                            <tr>
                              <td className={`${isString(objValues.state) && 'tdDataNumber'} tdData`} rowSpan={4}>
                                {objValues.state}
                              </td>
                              {Object.values(objValues.data[0]).map((val) => {
                                return <td className={`${isString(val) && 'tdDataNumber'}monthBackground`}>{val}</td>;
                              })}
                            </tr>
                          );
                        })}
                      </>
                    );
                  }
                  if (type === 'mdaCoverage') {
                    return (
                      <tr className="mdaData">
                        {Object.keys(objValues).map((obj, id) => {
                          if (Object.keys(objValues).length === id + 1) {
                            return (
                              <td colSpan={Object.keys(objValues).length === id + 1 ? 4 : ''} className="lastMdaData">
                                {Object.keys(objValues.data).map((item) => {
                                  return (
                                    <td
                                      className={`${
                                        objValues.data && isString(objValues.data[item]) && 'tdDataNumber'
                                      } tdData`}
                                    >
                                      {objValues.data && objValues.data[item]}
                                    </td>
                                  );
                                })}
                              </td>
                            );
                          }
                          return (
                            <td className={`${objValues && isString(objValues[obj]) && 'tdDataNumber'} tdData`}>
                              {objValues && objValues[obj]}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  }

                  return (
                    <tr onClick={() => rowClick && rowClick(objValues)} className={rowClick ? 'on-row-click' : ''}>
                      {Object.entries(objValues).map(([key, value]) => {
                        return <td className={`${isString(value) && 'tdDataNumber'} tdData`}>{value}</td>;
                      })}
                      {optional ? (
                        <td className="optionalIcons">
                          {view && (
                            <img
                              src={CustomIcons.View}
                              alt="views"
                              onClick={onViewData}
                              aria-hidden="true"
                              className="eyeView"
                            />
                          )}
                          {edit && (
                            <img
                              src={CustomIcons.Pencil}
                              className="editData"
                              alt="edit"
                              onClick={onEditData}
                              aria-hidden="true"
                            />
                          )}
                          {deleteData && (
                            <img
                              src={CustomIcons.Delete}
                              alt="delete"
                              onClick={onDeleteData}
                              aria-hidden="true"
                              className="deleteData"
                            />
                          )}
                        </td>
                      ) : (
                        <td className="optionalIcons">
                          {threshold && (
                            <img
                              src={CustomIcons.DownloadData}
                              alt="download"
                              onClick={downloadData}
                              aria-hidden="true"
                              className="createSpace"
                            />
                          )}
                          {proved && (
                            <img src={CustomIcons.DoubleTick} alt="proved" onClick={onProveStatus} aria-hidden="true" />
                          )}
                        </td>
                      )}
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      </Grid>

      <Grid className="pagination">
        <Pagination
          onClickPageBtn={mode === 'client' ? handleChangePage : onClickPageBtn}
          totalPages={5}
          currentPage={mode === 'client' ? parseInt(page + 1, 10) : currentPage || 1}
          onClickPerPage={mode === 'client' ? handleChangeRowsPerPage : onClickPerPage}
          currentLimit={mode === 'client' ? rowsPerPage : currentLimit}
          recordLength={rowData?.length}
          className="pagination"
        />
      </Grid>

      {inputTable && (
        <Grid container direction="row" item md={12} columnSpacing={12} className="btnViews">
          {upDatedData && (
            <Grid className="btnHolder">
              <CustomButton
                variants="contained"
                ellipse
                color="info"
                btnTitle={IsExcelData ? 'Submit' : upDatedData ? 'Update' : 'Submit'}
                onClickHandle={() => onSubmitUpdatedData(upDatedData ? 'Update' : 'Submit')}
                customClass="btnWidth"
              />
            </Grid>
          )}
          {downloadValidation && (
            <Grid className="btnHolder">
              <CustomButton
                variants="contained"
                ellipse
                color="disable"
                btnTitle="Cancel"
                customClass="btnWidth"
                onClickHandle={() => {
                  if (editDataCellView === true) {
                    setEditDataCellView(false);
                    setUpdatedData(false);
                    setTableDataEditAfter(true);
                  } else {
                    onCancel();
                    setUpdatedData(false);
                  }
                }}
              />
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default Table;
Table.propTypes = {
  header: PropTypes.arrayOf.isRequired,
  onClickPageBtn: PropTypes.func.isRequired,
  rows: PropTypes.arrayOf(PropTypes.objectOf).isRequired,
  mode: PropTypes.string,
  onClickPerPage: PropTypes.func,
  currentPage: PropTypes.number,
  type: PropTypes.string,
  currentLimit: PropTypes.number,
  inputTable: PropTypes.bool,
  masterSetup: PropTypes.bool,
  userActivity: PropTypes.string,
  onViewData: PropTypes.func,
  onEditData: PropTypes.func,
  onDeleteData: PropTypes.func,
  onProveStatus: PropTypes.func,
  downloadData: PropTypes.func,
  optional: PropTypes.bool,
  view: PropTypes.bool,
  edit: PropTypes.bool,
  IsExcelData: PropTypes.bool,
  deleteData: PropTypes.bool,
  threshold: PropTypes.bool,
  proved: PropTypes.bool,
  hideKeyFields: PropTypes.arrayOf(),
  showSno: PropTypes.bool,
  onSendUpdatedData: PropTypes.func,
  downloadUrl: PropTypes.string,
  onCancel: PropTypes.func,
  rowClick: PropTypes.func,
  dashboardEdit: PropTypes.bool,
  Activity: PropTypes.string,
  uniqueKey: PropTypes.string,
};
Table.defaultProps = {
  mode: 'client',
  onClickPerPage: () => {},
  type: 'normal',
  currentPage: 1,
  currentLimit: 5,
  inputTable: false,
  masterSetup: false,
  userActivity: '',
  onViewData: () => null,
  onEditData: () => null,
  onDeleteData: () => null,
  onProveStatus: () => null,
  downloadData: () => {},
  optional: false,
  view: false,
  edit: false,
  deleteData: false,
  threshold: false,
  proved: false,
  hideKeyFields: [],
  IsExcelData: false,
  showSno: false,
  onSendUpdatedData: () => null,
  downloadUrl: '',
  onCancel: () => null,
  rowClick: null,
  dashboardEdit: false,
  Activity: '',
  uniqueKey: '',
};