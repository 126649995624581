/* eslint-disable no-console */
/* eslint-disable import/no-duplicates */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable use-isnan */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable spaced-comment */
/* eslint-disable no-plusplus */
/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable require-jsdoc */
/* eslint-disable no-alert */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable camelcase */
/* eslint-disable dot-notation */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable prefer-template */
/* eslint-disable new-cap */

import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import axios from 'axios';
import 'leaflet.wms/dist/leaflet.wms';
import { object } from 'prop-types';
import { overlay } from 'leaflet.wms/dist/leaflet.wms';

const MapComponent = ({ selectedState, stateCoordinates, token, selectedStrategy, countryProfileMap }) => {
  
// axios.interceptors.request.use((config) => {
//   config.headers.Authorization =`Bearer ${token}`;
//   return config;
// });
  const endemicPopulations = countryProfileMap?.national?.['TAS 2 Pass']?.map(
    (item) => item['Endemic Population'],
  );
  const EndemicDistricts = countryProfileMap?.national?.['TAS 2 Pass']?.map((item) => item['Endemic Districts']);
  // const district = countryProfileMap?.national?.['TAS 2 Pass']?.map((item) => item.district);

  const Strategy = countryProfileMap?.national?.['TAS 2 Pass']?.map((item) => item.Strategy);
  const MDADistricts = countryProfileMap?.national?.['TAS 2 Pass']?.map((item) => item['MDA Districts']);
  const PreTASDistricts = countryProfileMap?.national?.['TAS 2 Pass']?.map((item) => item['Pre TAS Districts']);
  const TASclearedDistricts = countryProfileMap?.national?.['TAS 2 Pass']?.map(
    (item) => item['TAS cleared Districts'],
  );
  // console.log(selectedStrategy, 'selectedStrategy2222');
  const mapRef = useRef(null);

  const [map, setMap] = useState(null);
  const countryProfile = countryProfileMap;
  const district = [{ d: 'Arwal' }, { d: 'Bhojpur' }];

  const OVERLAY_BASE_URL = 'https://mgis-apiv2.mappls.com/map/getMap';

  const getTypeColor = (type) => {
    switch (type) {
      case 'IDA Completed':
        return 'fd9727';
      case 'DA Completed':
        return 'fc5830';
      case 'Pending DA':
        return 'a2ba67';
      case 'Pending IDA':
        return '79a30b';
      case 'PMS1':
        return 'a96192';
      case 'PMS2':
        return '9e1ecf';
      case 'PMS3':
        return '1eaacf';
      case 'Total Endemic Districts':
        return 'dbdbdb';
      // Add more cases here for other types...
      default:
        return 'ffffff';
    }
  };

  // console.log(color, 'color');
  const addStateLayer = (state) => {
    if (!stateCoordinates[state]) {
      console.error(`Coordinates not available for state: ${state}`, 'stateerror');
      return;
    }

    const [lat, lng] = stateCoordinates[state];

    if (lat === undefined || lng === undefined) {
      console.error(`Invalid coordinates for state: ${state}`);
      // map.removeLayer(layer);
      return;
    }
    const dataSetName = selectedStrategy === 'Block Strategy' ? 'ai_block_town' : 'ai_district';
    // console.log(dataSetName, 'dataSetName11111111');

    const styleName =
      selectedStrategy === 'Block Strategy'
        ? 'ai_block_town_label_1695630697996_workview'
        : 'ai_district_label_1692718543843_workview';
    const wmsParams1 = {
      access_token: `Bearer ${token}`,
      datasetNme: dataSetName,
      // cql_filter: `state='${state}'`,
      height: map.innerHeight,
      width: map.innerWidth,
      srs: 'EPSG:3857',
      styles: styleName,
      service: 'WMS',
      request: 'GetMap',
      version: '1.1.1',
      env: 'polygonfillcolor:ffffff',
      transparent: true,
      format: 'image/png',
      autoZIndex: true,
    };

    if (map && state) {
      map.setView([lat, lng], 4);

      map.eachLayer((layer) => {
        if (!(layer instanceof L.TileLayer)) {
          map.removeLayer(layer);
        }
      });

      const aiStateLayer = new L.TileLayer.WMS(OVERLAY_BASE_URL, wmsParams1);
      aiStateLayer.addTo(map);
      const aiStateLayer1 = (data, color) =>
        new L.TileLayer.WMS(OVERLAY_BASE_URL, {
          height: map.innerHeight,
          width: map.innerWidth,
          access_token: `Bearer ${token}`,
          datasetNme: dataSetName,
          // cql_filter: `district='${data}'`,
          srs: 'EPSG:3857',
          // styles: 'ai_district_label_1692718543843_workview',
          styles: 'ai_block_town_label_1695630697996_workview',
          service: 'WMS',
          request: 'GetMap',
          version: '1.1.1',
          env: `polygonfillcolor:${color}`,
          transparent: true,
          format: 'image/png',
          autoZIndex: true,
        });

      // const matchingDistricts = countryProfile?.state?.filter((profile) => profile?.state === selectedState);
      // console.log(matchingDistricts, 'matchingDistrictsM');
      // Add district layers
      // console.log(countryProfile, 'matchingDistrictsC');
      // console.log(countryProfileMap, 'matchingDistrictsCL');
      // countryProfile?.state?.length > 1 &&
      // countryProfileMap?.state?.length >= 1 &&
      //   countryProfileMap?.state
      //     ?.filter((profile) => profile?.state === selectedState)
      //     ?.forEach((districtObj) => {
      //       const districtLayer = aiStateLayer1(districtObj?.district, getTypeColor(districtObj?.type));
      //       districtLayer.addTo(map);
      //       // alert('kk');
      //       console.log(districtObj?.district, 'matchingDistrictsDD');
      //     });

      //DA Pass
      const DAPassArray = countryProfile?.national['DA Pass'];

      if (DAPassArray !== undefined && DAPassArray !== null && DAPassArray.length > 0) {
        const DA_Pass = DAPassArray?.map((item) => `'${item?.district}'`).join("'',''");

        // console.log(DA_Pass, 'DA_Pass...');
        const formattedDistricts1 = DA_Pass.replace(/'/g, ''); // Removing single quotes
        const spacedDistricts1 = formattedDistricts1.replace(/,/g, "', '"); // Adding spaces after commas

        const cqlFilter1 = `district in ('${spacedDistricts1}')`;

        const wmsParams2 = {
          access_token: `Bearer ${token}`,
          datasetNme: selectedStrategy === 'Block Strategy' ? 'ai_block_town' : 'ai_district',
          //   height: window.innerHeight,
          //    width: window.innerWidth,
          bbox: map.getBounds().toBBoxString(),
          cql_filter: cqlFilter1,
          srs: 'EPSG:3857',
          // styles: 'ai_district_label_1692718543843_workview',
          styles: styleName,
          service: 'WMS',
          request: 'GetMap',
          version: '1.1.1',
          env: `polygonfillcolor:fd9727`,
          // env: `polygonfillcolor:${color}`,
          transparent: true,
          format: 'image/png',
          autoZIndex: true,
        };

        const createAiStateLayer2 = (params) => {
          return overlay(OVERLAY_BASE_URL, params);
        };

        const aiStateLayer2 = createAiStateLayer2(wmsParams2);
        aiStateLayer2.addTo(map);
        console.log(wmsParams2, 'wsm2');
      }

      //IDM Pass
      const IDAPassArray = countryProfile?.national['IDA Pass'];

      if (IDAPassArray !== undefined && IDAPassArray !== null && IDAPassArray.length > 0) {
        const IDA_Pass = IDAPassArray?.map((item) => `'${item?.district}'`).join("'',''");

        console.log(IDA_Pass, 'IDA_Pass...');
        const formattedDistricts1 = IDA_Pass.replace(/'/g, ''); // Removing single quotes
        const spacedDistricts1 = formattedDistricts1.replace(/,/g, "', '"); // Adding spaces after commas

        const cqlFilter1 = `district in ('${spacedDistricts1}')`;

        const wmsParams2 = {
          access_token: `Bearer ${token}`,
          datasetNme: dataSetName,
          //   height: window.innerHeight,
          //    width: window.innerWidth,
          bbox: map.getBounds().toBBoxString(),
          cql_filter: cqlFilter1,
          srs: 'EPSG:3857',
          styles: styleName,
          service: 'WMS',
          request: 'GetMap',
          version: '1.1.1',
          env: `polygonfillcolor:fd9727`,
          // env: `polygonfillcolor:${color}`,
          transparent: true,
          format: 'image/png',
          autoZIndex: true,
        };

        const createAiStateLayer2 = (params) => {
          return overlay(OVERLAY_BASE_URL, params);
        };

        const aiStateLayer2 = createAiStateLayer2(wmsParams2);
        aiStateLayer2.addTo(map);
        console.log(wmsParams2, 'wsm2');
      }

      //Under DA

      const UnderDAArray = countryProfile?.national['Pending DA'];

      if (UnderDAArray !== undefined && UnderDAArray !== null && UnderDAArray.length > 0) {
        const Under_DA = UnderDAArray?.map((item) => `'${item?.district}'`).join("'',''");

        console.log(Under_DA, 'UNDER_DA');

        const formattedDistricts3 = Under_DA.replace(/'/g, ''); // Removing single quotes
        const spacedDistricts3 = formattedDistricts3.replace(/,/g, "', '"); // Adding spaces after commas

        const cqlFilter3 = `district in ('${spacedDistricts3}')`;

        const wmsParams3 = {
          access_token: `Bearer ${token}`,
          datasetNme: dataSetName,
          //  height: window.innerHeight,
          //  width: window.innerWidth,
          bbox: map.getBounds().toBBoxString(),
          cql_filter: cqlFilter3,
          srs: 'EPSG:3857',
          // styles: 'ai_district_label_1692718543843_workview',
          styles: styleName,

          service: 'WMS',
          request: 'GetMap',
          version: '1.1.1',
          env: `polygonfillcolor:a2ba67`,
          // env: `polygonfillcolor:${color}`,
          transparent: true,
          format: 'image/png',
          autoZIndex: true,
        };

        const createAiStateLayer3 = (params) => {
          return overlay(OVERLAY_BASE_URL, params);
        };

        const aiStateLayer3 = createAiStateLayer3(wmsParams3);
        aiStateLayer3.addTo(map);
        console.log(wmsParams3, 'wsm3');
      }

      //Under_IDA;
      const Under_IDA_Array = countryProfile?.national['Pending IDA'];

      if (Under_IDA_Array !== undefined && Under_IDA_Array !== null && Under_IDA_Array.length > 0) {
        const Under_IDA = Under_IDA_Array.map((item) => `'${item.district}'`).join("'',''");

        const formattedDistricts4 = Under_IDA.replace(/'/g, ''); // Removing single quotes
        const spacedDistricts4 = formattedDistricts4.replace(/,/g, "', '"); // Adding spaces after commas

        const cqlFilter4 = `district in ('${spacedDistricts4}')`;

        const wmsParams4 = {
          access_token: `Bearer ${token}`,
          datasetNme: dataSetName,
          // height: window.innerHeight,
          //  width: window.innerWidth,
          bbox: map.getBounds().toBBoxString(),
          cql_filter: cqlFilter4,
          srs: 'EPSG:3857',
          // styles: 'ai_district_label_1692718543843_workview',
          styles: styleName,

          service: 'WMS',
          request: 'GetMap',
          version: '1.1.1',
          env: `polygonfillcolor:79a30b`,
          // env: `polygonfillcolor:${color}`,
          transparent: true,
          format: 'image/png',
          autoZIndex: true,
        };

        const createAiStateLayer4 = (params) => {
          return overlay(OVERLAY_BASE_URL, params);
        };

        const aiStateLayer4 = createAiStateLayer4(wmsParams4);
        aiStateLayer4.addTo(map);
        console.log(wmsParams4, 'wsm4');
      }
      //PMS1

      const PMS1_Array = countryProfile?.national['PMS1'];

      if (PMS1_Array !== undefined && PMS1_Array !== null && PMS1_Array.length > 0) {
        const PMS1 = PMS1_Array.map((item) => `'${item.district}'`).join("'',''");

        const formattedDistricts5 = PMS1.replace(/'/g, ''); // Removing single quotes
        const spacedDistricts5 = formattedDistricts5.replace(/,/g, "', '"); // Adding spaces after commas

        const cqlFilter5 = `district in ('${spacedDistricts5}')`;

        const wmsParams5 = {
          access_token: `Bearer ${token}`,
          datasetNme: dataSetName,
          //   height: window.innerHeight,
          //    width: window.innerWidth,
          bbox: map.getBounds().toBBoxString(),
          cql_filter: cqlFilter5,
          srs: 'EPSG:3857',
          // styles: 'ai_district_label_1692718543843_workview',
          styles: styleName,

          service: 'WMS',
          request: 'GetMap',
          version: '1.1.1',
          env: `polygonfillcolor:a96192`,
          // env: `polygonfillcolor:${color}`,
          transparent: true,
          format: 'image/png',
          autoZIndex: true,
        };

        const createAiStateLayer5 = (params) => {
          return overlay(OVERLAY_BASE_URL, params);
        };

        const aiStateLayer5 = createAiStateLayer5(wmsParams5);
        aiStateLayer5.addTo(map);
        console.log(wmsParams5, 'wsm5');
      }

      // PMS2;

      const PMS2_Array = countryProfile?.national['PMS2'];

      if (PMS2_Array !== undefined && PMS2_Array !== null && PMS2_Array.length > 0) {
        const PMS2 = PMS2_Array.map((item) => `'${item.district}'`).join("'',''");

        const formattedDistricts6 = PMS2.replace(/'/g, ''); // Removing single quotes
        const spacedDistricts6 = formattedDistricts6.replace(/,/g, "', '"); // Adding spaces after commas

        const cqlFilter6 = `district in ('${spacedDistricts6}')`;

        const wmsParams6 = {
          access_token: `Bearer ${token}`,
          datasetNme: dataSetName,
          // height: window.innerHeight,
          //  width: window.innerWidth,
          bbox: map.getBounds().toBBoxString(),
          cql_filter: cqlFilter6,
          srs: 'EPSG:3857',
          // styles: 'ai_district_label_1692718543843_workview',
          styles: styleName,

          service: 'WMS',
          request: 'GetMap',
          version: '1.1.1',
          env: `polygonfillcolor:9e1ecf`,
          // env: `polygonfillcolor:${color}`,
          transparent: true,
          format: 'image/png',
          autoZIndex: true,
        };

        const createAiStateLayer6 = (params) => {
          return overlay(OVERLAY_BASE_URL, params);
        };

        const aiStateLayer6 = createAiStateLayer6(wmsParams6);
        aiStateLayer6.addTo(map);
        console.log(wmsParams6, 'wsm6');
      }

      // PMS3
      const PMS3_Array = countryProfile?.national['PMS3'];

      if (PMS3_Array !== undefined && PMS3_Array !== null && PMS3_Array.length > 0) {
        const PMS3 = PMS3_Array.map((item) => `'${item.district}'`).join("'',''");
        const formattedDistricts7 = PMS3.replace(/'/g, '');
        const spacedDistricts7 = formattedDistricts7.replace(/,/g, "', '"); // Adding spaces after commas

        const cqlFilter7 = `district in ('${spacedDistricts7}')`;

        const wmsParams7 = {
          access_token: `Bearer ${token}`,
          datasetNme: dataSetName,
          // height: window.innerHeight,
          //    width: window.innerWidth,
          bbox: map.getBounds().toBBoxString(),
          cql_filter: cqlFilter7,
          srs: 'EPSG:3857',
          // styles: 'ai_district_label_1692718543843_workview',
          styles: styleName,

          service: 'WMS',
          request: 'GetMap',
          version: '1.1.1',
          env: `polygonfillcolor:1eaacf`,
          // env: `polygonfillcolor:${color}`,
          transparent: true,
          format: 'image/png',
          autoZIndex: true,
        };

        const createAiStateLayer7 = (params) => {
          return overlay(OVERLAY_BASE_URL, params);
        };

        const aiStateLayer7 = createAiStateLayer7(wmsParams7);
        aiStateLayer7.addTo(map);
        console.log(wmsParams7, 'wsm7');
      }

      //Pending Pre TAS
      const Pending_Pre_TAS_Array = countryProfile?.national['Pending Pre TAS'];

      if (Pending_Pre_TAS_Array !== undefined && Pending_Pre_TAS_Array !== null && Pending_Pre_TAS_Array.length > 0) {
        const Pending_Pre_TAS = Pending_Pre_TAS_Array.map((item) => `'${item.district}'`).join("'',''");

        const formattedDistricts8 = Pending_Pre_TAS.replace(/'/g, ''); // Removing single quotes
        const spacedDistricts8 = formattedDistricts8.replace(/,/g, "', '"); // Adding spaces after commas

        const cqlFilter8 = `district in ('${spacedDistricts8}')`;

        const wmsParams8 = {
          access_token: `Bearer ${token}`,
          datasetNme: dataSetName,
          //    height: window.innerHeight,
          //     width: window.innerWidth,
          bbox: map.getBounds().toBBoxString(),
          cql_filter: cqlFilter8,
          srs: 'EPSG:3857',
          // styles: 'ai_district_label_1692718543843_workview',
          styles: styleName,

          service: 'WMS',
          request: 'GetMap',
          version: '1.1.1',
          //
          env: `polygonfillcolor:FED602`,
          // env: `polygonfillcolor:${color}`,
          transparent: true,
          format: 'image/png',
          autoZIndex: true,
        };

        const createAiStateLayer8 = (params) => {
          return overlay(OVERLAY_BASE_URL, params);
        };

        const aiStateLayer8 = createAiStateLayer8(wmsParams8);
        aiStateLayer8.addTo(map);
        console.log(wmsParams8, 'wsm8');
      }

      //Pending TAS 1
      const Pending_TAS_1_Array = countryProfile?.national['Pending TAS 1'];

      if (Pending_TAS_1_Array !== undefined && Pending_TAS_1_Array !== null && Pending_TAS_1_Array.length > 0) {
        const Pending_TAS_1 = Pending_TAS_1_Array.map((item) => `'${item.district}'`).join("'',''");

        const formattedDistricts8 = Pending_TAS_1.replace(/'/g, ''); // Removing single quotes
        const spacedDistricts8 = formattedDistricts8.replace(/,/g, "', '"); // Adding spaces after commas

        const cqlFilter8 = `district in ('${spacedDistricts8}')`;

        const wmsParams8 = {
          access_token: `Bearer ${token}`,
          datasetNme: dataSetName,
          //    height: window.innerHeight,
          //     width: window.innerWidth,
          bbox: map.getBounds().toBBoxString(),
          cql_filter: cqlFilter8,
          srs: 'EPSG:3857',
          // styles: 'ai_district_label_1692718543843_workview',
          styles: styleName,

          service: 'WMS',
          request: 'GetMap',
          version: '1.1.1',
          //
          env: `polygonfillcolor:1DAAF1`,
          // env: `polygonfillcolor:${color}`,
          transparent: true,
          format: 'image/png',
          autoZIndex: true,
        };

        const createAiStateLayer8 = (params) => {
          return overlay(OVERLAY_BASE_URL, params);
        };

        const aiStateLayer8 = createAiStateLayer8(wmsParams8);
        aiStateLayer8.addTo(map);
        console.log(wmsParams8, 'wsm8');
      }

      //Pending TAS 2
      const Pending_TAS_2_Array = countryProfile?.national['Pending TAS 2'];

      if (Pending_TAS_2_Array !== undefined && Pending_TAS_2_Array !== null && Pending_TAS_2_Array.length > 0) {
        const Pending_TAS_2 = Pending_TAS_2_Array.map((item) => `'${item.district}'`).join("'',''");

        const formattedDistricts8 = Pending_TAS_2.replace(/'/g, ''); // Removing single quotes
        const spacedDistricts8 = formattedDistricts8.replace(/,/g, "', '"); // Adding spaces after commas

        const cqlFilter8 = `district in ('${spacedDistricts8}')`;

        const wmsParams8 = {
          access_token: `Bearer ${token}`,
          datasetNme: dataSetName,
          //    height: window.innerHeight,
          //     width: window.innerWidth,
          bbox: map.getBounds().toBBoxString(),
          cql_filter: cqlFilter8,
          srs: 'EPSG:3857',
          // styles: 'ai_district_label_1692718543843_workview',
          styles: styleName,

          service: 'WMS',
          request: 'GetMap',
          version: '1.1.1',
          //
          env: `polygonfillcolor:577BF8`,
          // env: `polygonfillcolor:${color}`,
          transparent: true,
          format: 'image/png',
          autoZIndex: true,
        };

        const createAiStateLayer8 = (params) => {
          return overlay(OVERLAY_BASE_URL, params);
        };

        const aiStateLayer8 = createAiStateLayer8(wmsParams8);
        aiStateLayer8.addTo(map);
        console.log(wmsParams8, 'wsm8');
      }

      //Pending TAS 3
      const Pending_TAS_3_Array = countryProfile?.national['Pending TAS 3'];

      if (Pending_TAS_3_Array !== undefined && Pending_TAS_3_Array !== null && Pending_TAS_3_Array.length > 0) {
        const Pending_TAS_3 = Pending_TAS_3_Array.map((item) => `'${item.district}'`).join("'',''");

        const formattedDistricts8 = Pending_TAS_3.replace(/'/g, ''); // Removing single quotes
        const spacedDistricts8 = formattedDistricts8.replace(/,/g, "', '"); // Adding spaces after commas

        const cqlFilter8 = `district in ('${spacedDistricts8}')`;

        const wmsParams8 = {
          access_token: `Bearer ${token}`,
          datasetNme: dataSetName,
          //    height: window.innerHeight,
          //     width: window.innerWidth,
          bbox: map.getBounds().toBBoxString(),
          cql_filter: cqlFilter8,
          srs: 'EPSG:3857',
          // styles: 'ai_district_label_1692718543843_workview',
          styles: styleName,

          service: 'WMS',
          request: 'GetMap',
          version: '1.1.1',
          //
          env: `polygonfillcolor:149588`,
          // env: `polygonfillcolor:${color}`,
          transparent: true,
          format: 'image/png',
          autoZIndex: true,
        };

        const createAiStateLayer8 = (params) => {
          return overlay(OVERLAY_BASE_URL, params);
        };

        const aiStateLayer8 = createAiStateLayer8(wmsParams8);
        aiStateLayer8.addTo(map);
        console.log(wmsParams8, 'wsm8');
      }

      //Pre TAS Pass
      const TAS_Pass_Array = countryProfile?.national['Pre TAS Pass'];

      if (TAS_Pass_Array !== undefined && TAS_Pass_Array !== null && TAS_Pass_Array.length > 0) {
        const TAS_Pass = TAS_Pass_Array.map((item) => `'${item.district}'`).join("'',''");

        const formattedDistricts8 = TAS_Pass.replace(/'/g, ''); // Removing single quotes
        const spacedDistricts8 = formattedDistricts8.replace(/,/g, "', '"); // Adding spaces after commas

        const cqlFilter8 = `district in ('${spacedDistricts8}')`;

        const wmsParams8 = {
          access_token: `Bearer ${token}`,
          datasetNme: dataSetName,
          //    height: window.innerHeight,
          //     width: window.innerWidth,
          bbox: map.getBounds().toBBoxString(),
          cql_filter: cqlFilter8,
          srs: 'EPSG:3857',
          // styles: 'ai_district_label_1692718543843_workview',
          styles: styleName,

          service: 'WMS',
          request: 'GetMap',
          version: '1.1.1',
          //
          env: `polygonfillcolor:B48E7D`,
          // env: `polygonfillcolor:${color}`,
          transparent: true,
          format: 'image/png',
          autoZIndex: true,
        };

        const createAiStateLayer8 = (params) => {
          return overlay(OVERLAY_BASE_URL, params);
        };

        const aiStateLayer8 = createAiStateLayer8(wmsParams8);
        aiStateLayer8.addTo(map);
        console.log(wmsParams8, 'wsm8');
      }

      //TAS 1
      const TAS1_Array = countryProfile?.national['TAS 1 Pass'];

      if (TAS1_Array !== undefined && TAS1_Array !== null && TAS1_Array.length > 0) {
        const TAS1 = TAS1_Array.map((item) => `'${item.district}'`).join("'',''");

        const formattedDistricts8 = TAS1.replace(/'/g, ''); // Removing single quotes
        const spacedDistricts8 = formattedDistricts8.replace(/,/g, "', '"); // Adding spaces after commas

        const cqlFilter8 = `district in ('${spacedDistricts8}')`;

        const wmsParams8 = {
          access_token: `Bearer ${token}`,
          datasetNme: dataSetName,
          //    height: window.innerHeight,
          //     width: window.innerWidth,
          bbox: map.getBounds().toBBoxString(),
          cql_filter: cqlFilter8,
          srs: 'EPSG:3857',
          // styles: 'ai_district_label_1692718543843_workview',
          styles: styleName,

          service: 'WMS',
          request: 'GetMap',
          version: '1.1.1',
          env: `polygonfillcolor:fc5830`,
          // env: `polygonfillcolor:${color}`,
          transparent: true,
          format: 'image/png',
          autoZIndex: true,
        };

        const createAiStateLayer8 = (params) => {
          return overlay(OVERLAY_BASE_URL, params);
        };

        const aiStateLayer8 = createAiStateLayer8(wmsParams8);
        aiStateLayer8.addTo(map);
        console.log(wmsParams8, 'wsm8');
      }

      //TAS 2
      const TAS2_Array = countryProfile?.national['TAS 2 Pass'];

      if (TAS2_Array !== undefined && TAS2_Array !== null && TAS2_Array.length > 0) {
        const TAS2 = TAS2_Array.map((item) => `'${item.district}'`).join("'',''");

        const formattedDistricts9 = TAS2.replace(/'/g, ''); // Removing single quotes
        const spacedDistricts9 = formattedDistricts9.replace(/,/g, "', '"); // Adding spaces after commas

        const cqlFilter9 = `district in ('${spacedDistricts9}')`;

        const wmsParams9 = {
          access_token: `Bearer ${token}`,
          datasetNme: dataSetName,
          //    height: window.innerHeight,
          //     width: window.innerWidth,
          bbox: map.getBounds().toBBoxString(),
          cql_filter: cqlFilter9,
          srs: 'EPSG:3857',
          // styles: 'ai_district_label_1692718543843_workview',
          styles: styleName,

          service: 'WMS',
          request: 'GetMap',
          version: '1.1.1',
          env: `polygonfillcolor:2d992c`,
          // env: `polygonfillcolor:${color}`,
          transparent: true,
          format: 'image/png',
          autoZIndex: true,
        };

        const createAiStateLayer9 = (params) => {
          return overlay(OVERLAY_BASE_URL, params);
        };

        const aiStateLayer9 = createAiStateLayer9(wmsParams9);
        aiStateLayer9.addTo(map);
        console.log(wmsParams9, 'wsm9');
      }
      //TAS 3
      const TAS3_Array = countryProfile?.national['TAS 3 Pass'];

      if (TAS3_Array !== undefined && TAS3_Array !== null && TAS3_Array.length > 0) {
        const TAS3 = TAS3_Array.map((item) => `'${item.district}'`).join("'',''");

        const formattedDistricts10 = TAS3.replace(/'/g, ''); // Removing single quotes
        const spacedDistricts10 = formattedDistricts10.replace(/,/g, "', '"); // Adding spaces after commas

        const cqlFilter10 = `district in ('${spacedDistricts10}')`;

        const wmsParams10 = {
          access_token: `Bearer ${token}`,
          datasetNme: dataSetName,
          //    height: window.innerHeight,
          //     width: window.innerWidth,
          bbox: map.getBounds().toBBoxString(),
          cql_filter: cqlFilter10,
          srs: 'EPSG:3857',
          // styles: 'ai_district_label_1692718543843_workview',
          styles: styleName,

          service: 'WMS',
          request: 'GetMap',
          version: '1.1.1',
          env: `polygonfillcolor:fc5830`,
          // env: `polygonfillcolor:${color}`,
          transparent: true,
          format: 'image/png',
          autoZIndex: true,
        };

        const createAiStateLayer10 = (params) => {
          return overlay(OVERLAY_BASE_URL, params);
        };

        const aiStateLayer10 = createAiStateLayer10(wmsParams10);
        aiStateLayer10.addTo(map);
        console.log(wmsParams10, 'wsm10');
      }
      // console.log(countryProfile, 'matchingDistricts2');
      // console.log(countryProfile?.length, 'matchingDistricts3');
      // const attributionControl = L.control.attribution({
      //   prefix: '',
      // });

      // attributionControl.addTo(map);
    }
  };

  useEffect(() => {
    const mapInstance = L.map(mapRef.current, {
      center: [25.0961, 85.3131],
      attributionControl: false,
      zoom: 7,
      scrollWheelZoom: false,
      zoomControl: true,
      dragging: true,
    });

    setMap(mapInstance);

    return () => {
      if (mapInstance) {
        mapInstance.remove();
      }
    };
  }, []);

  useEffect(() => {
    if (map && map !== null && selectedState) {
      addStateLayer(selectedState);
      map.on('click', function (event) {
        const point = map.latLngToContainerPoint(event.latlng, map.getZoom());
        const size = map.getSize();
        const params = {
          request: 'GetFeatureInfo',
          datasetNme: 'ai_district',
          styles: 'ai_district',
          service: 'WMS',
          // srs: 'EPSG:4326',
          transparent: true,
          format: 'image/png',
          bbox: map.getBounds().toBBoxString(),
          height: size.y,
          width: size.x,
          buffer: 15,
          crossDomain: true,
          version: '1.1.1',
          x: parseInt(point.x, 10),
          y: parseInt(point.y, 10),
        };

        if (selectedState) {
          params['cql_filter'] = `state = '${selectedState}'`;
        }

        const geoserver = 'https://mgis-apiv2.mappls.com/map/getFeatureInfo';
        const urls = geoserver + L.Util.getParamString(params, geoserver, false);
        axios
          .get(urls, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            const response = res.data.featureInfo.featureInfo[0];
            console.log('log API', response);

            const popupList =
              `</div><div style="width:100%; height:110px; background-color:#fff;"><div class="toolTipTextSec">` +
              `        <div><label>STRATEGY</label><span> : ${Strategy}</span></div>` +
              `        <div><label>STRATEGY</label><span> : ${response?.properties?.district}</span></div>` +
              `        <div><label>ENDEMIC DISTRICTS</label><span> : ${EndemicDistricts}</span></div>` +
              `        <div><label>ENDEMIC POPULATIONS</label><span> : ${endemicPopulations}</span></div>` +
              `        <div><label>MDA DISTRICTS</label><span> : ${MDADistricts}</span></div>` +
              `        <div><label>PRE TAS DISTRICTS</label><span> : ${PreTASDistricts}</span></div>` +
              `        <div><label>TAS CLEARED DISTRICTS</label><span> : ${TASclearedDistricts}</span></div>` +
              `</div>` +
              `</div></div>`;

            const popup = L.popup();
            popup.setLatLng(event.latlng).setContent(popupList).openOn(map);

            console.log('you clicked latlng at :', event.latlng);
          })
          .catch((error) => console.log(error));
      });
    }
  }, [map, selectedState, addStateLayer]);

  return (
    <div style={{ margin: '0 auto', textAlign: 'center' }}>
      <div id="map" ref={mapRef} style={{ minHeight: '80vh', background: 'none', height: 'auto', width: '100%' }} />
    </div>
  );
};

MapComponent.propTypes = {
  selectedState: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  countryProfileMap: PropTypes.arrayOf(object).isRequired,
  stateCoordinates: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.number)).isRequired,
};

export default MapComponent;