/* eslint-disable import/order */
/* eslint-disable spaced-comment */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable require-jsdoc */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import TooltipTitle from '../../../components/atoms/Tooltip/TooltipContent';
import DashboardCard from '../../../components/atoms/DashboardCard';
import Typography from '../../../components/atoms/Typography';
import './styles.css';
import HeaderSection from '../../../components/molecules/HeaderSection';
import CommonTitledTable from '../../../components/organisms/CommonTitledTable';
import ActivityCalendar from '../../../components/organisms/Schedule/index';
import GroupBarChart from '../../../components/molecules/GroupedBarChart';
import DynChartBlock from '../../../components/organisms/DynChartBlock';
import DistrictMap from '../../../components/organisms/Map';
import FileGenerator from '../../../components/organisms/FileGenerator';
import Loader from '../../../components/atoms/Loader';
import StackedBarLineChart from '../../../components/molecules/StackedBarLineChart';
import { stateCoordinates, stateOptions } from '../../../components/mapcomponent/mapData';
import MapComponent from '../../../components/mapcomponent/mapcomponent';
import axios from 'axios';
import {
  countryHeader,
  progressHeader,
  decisionMatrixHeader,
  mdaHeader,
  preTasDataHead,
  // ActivityTypeCode,
  activityHeader,
  NationalDataQualityHeader,
  activityTableHead,
  // activityTableContent,
  tasSummary,
} from '../DummyConstants';
import {
  COUNTRY_PROFILE_TABLE,
  NATIONAL_PROGRESS_STATUS,
  COUNTRY_PROFILE_MAP,
  NATIONAL_STATES_DASHBOARD_CARD,
  NATIONAL_PROGRESS_STATUSBAR_GRAPH,
  ACTIVITY_CALENDAR,
  ACTIVITY_CALENDAR_TABLE,
  PRETAS_OVERVIEW,
  MDA_COVERAGE,
  GET_DATA_QUALITY,
  GET_TAS_DECISION_MATRIX,
  GET_DATA_QUALITY_GRAPH,
  GET_COUNTRY_PROFILE_CHART,
  GET_DISTRICT_MOVEDTO,
  GET_TAS_SUMMERY,
  GET_PROGRAM_ACTIVITY_TYPE,
  GET_STATE_DROPDOWN,
  GET_STRATEGY_DROPDOWN,
  GET_DISTRICT_DROPDOWN,
  SET_GET_DASHBOARD_FILTER_DATA,
} from '../../../constants/actionType/index';

/**
 * @name NationalDashboard
 * @param  {object} props - required props
 * @param {Array} props.showDisplay - showDisplay
 * @returns {React.ReactElement} return the national dashboard page
 */
const NationalDashboard = ({ showDisplay }) => {
  /**
   * @param {Array} list required dropdownlist
   * @returns {Array} array
   */
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(true);
  const {
    countryProfileTable,
    progressStatus,
    countryProfileMap,
    nationalStateDashboardCard,
    activityCalendar,
    activityCalendarTable,
    dataQuality,
    nationalStateProgressStatusbar,
    tasDecisionMatrix,
    dataQualityGraph,
    activityLegends,
    getCountryProfileChart,
    filterData,
  } = useSelector((state) => state.dashboardDetail);

  console.log(getCountryProfileChart, 'getCountryProfileChart');
  const { preTasOverview, MdaCoverage, DistrictMovedToData, getTasSummery } = useSelector(
    (state) => state.nationalDashboardDetails,
  );
  // console.log(DistrictMovedToData,"DistrictMovedToData");
  
  const dropdownList = useSelector((state) => state.dropDownReducer);

  const [getProgressLegends, setGetProgressLegends] = useState([]);
  const [selectedStrategy, setSelectedStrategy] = useState('');

  const [tasDecisionMatrixData, setTasDecisionMatrixData] = useState([]);
  const [getMapLegends, setGetMapLegends] = useState([]);
  const [token, setToken] = useState('');
  const [checkstates, setCheckstates] = useState('');
  const [globalFilter, setGlobalFilter] = useState({
    Year: new Date().getFullYear().toString(),
    State: '',
    Strategy: 'District Strategy',
    Type: '0',
    Sort: 'A-Z',
  });
  const [selectedStateYear, setSelectedData] = useState({ Year: globalFilter.Year, State: 'Odisha' });
  const [selectedDistrict, setSelectedDistrict] = useState('');

  const [filterValues, setFilterValues] = useState({
    nationalDetails: {
      Year: globalFilter.Year,
      State: '',
      'Country Profile Activity': '',
      Status: '',
      District: '',
      Type: '0',
      Strategy: globalFilter.Strategy,
    },
    progressDetails: {
      Type: 0,
      Year: globalFilter.Year,
      State: '',
      Activity: '',
      Status: '',
      Sort: globalFilter.Sort,
      Strategy: globalFilter.Strategy,
    },
    ProgressSortType: {
      Sort: globalFilter.Sort,
    },
    tasSummeryDetails: {
      Year: globalFilter.Year,
      State: '',
      Sort: globalFilter.Sort,
      Status: '',
      District: '',
      Strategy: globalFilter.Strategy,
    },
    ActivityDetails: {
      Year: globalFilter.Year,
      State: '',
      Activity: '',
      District: '',
      'Calendar Status': '',
      Month: '',
      type: 'national',
      Strategy: globalFilter.Strategy,
    },
    ActivityTableDetails: {
      Activity: '',
      Status: '',
      Sort: globalFilter.Sort,
    },
    MdaCoverageDetails: {
      Year: globalFilter.Year,
      State: '',
      Activity: '',
      Sort: globalFilter.Sort,
      Strategy: globalFilter.Strategy,
    },
    TasMatrixDetails: {
      Year: globalFilter.Year,
      Sort: globalFilter.Sort,
      Activity: '',
      Status: '',
      District: '',
      State: '',
      Strategy: globalFilter.Strategy,
    },
    PreTasOverviewDetails: {
      Year: globalFilter.Year,
      Sort: globalFilter.Sort,
      Status: '',
      State: '',
      Strategy: globalFilter.Strategy,
    },
    dataQualityGraphDetails: {
      Year: globalFilter.Year,
      State: '',
      District: '',
      Activity: '',
      Strategy: globalFilter.Strategy,
    },
    DataQualityDetails: {
      Sort: globalFilter.Sort,
    },
    NationalProfileSort: {
      Sort: globalFilter.Sort,
    },
  });
  console.log(globalFilter, 'globalFilterNational');
  useEffect(() => {
    return () => {
      dispatch({
        type: SET_GET_DASHBOARD_FILTER_DATA,
        payload: { data: { ...filterData, national: { initialLoad: true } } },
      });
    };
  }, []);

  const [districtList, setDistrictList] = useState({
    selectedKey: '',
    cards: [],
    countryProfile: [],
    progressStatus: [],
    activity: [],
    preTas: [],
    tasDecision: [],
    tasSummary: [],
    mdaCoverage: [],
    dataQuality: [],
  });

  const removeDuplicates = (arr) => [...new Set(arr)];

  useEffect(() => {
    if (!filterData?.national?.initialLoad) {
      dispatch({
        type: COUNTRY_PROFILE_TABLE,
        payload: { ...filterValues.NationalProfileSort, ...filterValues.nationalDetails },
      });
      dispatch({ type: NATIONAL_PROGRESS_STATUS, payload: filterValues.progressDetails });
      dispatch({ type: NATIONAL_STATES_DASHBOARD_CARD, payload: globalFilter, dashboardType: 'national' });
      dispatch({ type: GET_COUNTRY_PROFILE_CHART, payload: filterValues.nationalDetails, dashboardType: 'national' });
      dispatch({ type: COUNTRY_PROFILE_MAP, payload: filterValues.nationalDetails, dashboardType: 'national' });
      dispatch({ type: GET_TAS_SUMMERY, payload: filterValues.nationalDetails });
      dispatch({
        type: NATIONAL_PROGRESS_STATUSBAR_GRAPH,
        payload: filterValues.progressDetails,
        dashboardType: 'national',
      });
      dispatch({ type: ACTIVITY_CALENDAR, payload: filterValues.ActivityDetails, dashboardType: 'national' });
      dispatch({ type: MDA_COVERAGE, payload: filterValues.MdaCoverageDetails });
      dispatch({ type: GET_DATA_QUALITY, payload: filterValues.DataQualityDetails, dashboardType: 'national' });
      dispatch({ type: GET_DISTRICT_MOVEDTO, payload: filterValues.TasMatrixDetails, dashboardType: 'national' });
      dispatch({ type: GET_TAS_DECISION_MATRIX, payload: filterValues.TasMatrixDetails, dashboardType: 'national' });
      dispatch({ type: PRETAS_OVERVIEW, payload: filterValues.PreTasOverviewDetails });
      dispatch({
        type: GET_DATA_QUALITY_GRAPH,
        payload: filterValues.dataQualityGraphDetails,
        dashboardType: 'national',
      });
      dispatch({ type: GET_PROGRAM_ACTIVITY_TYPE });
      dispatch({ type: GET_STATE_DROPDOWN });
      dispatch({ type: GET_STRATEGY_DROPDOWN });
    }
  }, []);

  useEffect(() => {
    if (!filterData?.national?.initialLoad) {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  }, []);

  useEffect(() => {
    if (getCountryProfileChart?.national && getCountryProfileChart?.national.length > 0) {
      const getActivities = [];
      getCountryProfileChart?.national.map((parentData) => {
        parentData.data.map((childData) => {
          if (childData.value && childData.value !== 0) {
            getActivities.push(childData.name);
          }
          return true;
        });
        return true;
      });
      setGetProgressLegends(removeDuplicates(getActivities));
    } else {
      setGetProgressLegends([]);
    }
  }, [getCountryProfileChart?.national]);

  useEffect(() => {
    if (countryProfileMap?.national && countryProfileMap?.national.length > 0) {
      const getActivities = [];
      countryProfileMap?.national.map((parentData) => {
        getActivities.push(parentData.type);
        return true;
      });

      setGetMapLegends(removeDuplicates(getActivities));
    } else {
      setGetMapLegends([]);
    }
  }, [countryProfileMap?.national]);

  React.useMemo(() => {
    if (districtList.selectedKey !== 'cards') {
      setDistrictList({ ...districtList, [districtList.selectedKey]: dropdownList.districtData });
    } else if (districtList.selectedKey === 'cards') {
      setDistrictList({
        selectedKey: '',
        tasSummary: dropdownList.districtData,
        tasDecision: dropdownList.districtData,
      });
    }
  }, [dropdownList?.districtData]);

  /**
   * @param {Array} data info
   * @param {string} key key
   */
  const getSelectValue = (data, key) => {
    console.log(key, 'GET KEY');
    console.log(data, 'GET DATA');
    if (key === 'map') {
      const dataValue = data.value;
      const fieldName =
        data.fieldName === 'Country Profile Activity' || data.fieldName === 'Country Profile Status'
          ? 'Activity'
          : data.fieldName;
      dispatch({
        type: GET_COUNTRY_PROFILE_CHART,
        payload: { ...filterValues.nationalDetails, [fieldName]: data.value },
        dashboardType: 'national',
      });
      dispatch({
        type: COUNTRY_PROFILE_MAP,
        payload: { ...filterValues.nationalDetails, [fieldName]: data.value },
        dashboardType: 'national',
      });

      setCheckstates(data.value);
      if (fieldName === 'State') {
        setCheckstates(dataValue);
        setSelectedDistrict('All');
      }

      if (fieldName === 'District') {
        setSelectedDistrict(dataValue);
      }
      if (data.fieldName === 'Strategy') {
        setSelectedStrategy(dataValue);
      }

      // dispatch({ type: GET_TAS_SUMMERY, payload: { ...filterValues.nationalDetails, [fieldName]: data.value } });
      dispatch({
        type: COUNTRY_PROFILE_TABLE,
        payload: { ...filterValues.NationalProfileSort, ...filterValues.nationalDetails, [fieldName]: data.value },
      });
      setFilterValues({
        ...filterValues,
        nationalDetails: { ...filterValues.nationalDetails, [data.fieldName]: data.value },
      });
      if (data.fieldName === 'Year') setSelectedData({ ...selectedStateYear, [data.fieldName]: dataValue });
      if (data.fieldName === 'State') setSelectedData({ ...selectedStateYear, [data.fieldName]: dataValue });
    }
    if (key === 'progress') {
      dispatch({
        type: NATIONAL_PROGRESS_STATUSBAR_GRAPH,
        payload: { ...filterValues.progressDetails, [data.fieldName]: data.value },
        dashboardType: 'national',
      });
      dispatch({
        type: NATIONAL_PROGRESS_STATUS,
        payload: { ...filterValues.progressDetails, [data.fieldName]: data.value },
      });
      setFilterValues({
        ...filterValues,
        progressDetails: { ...filterValues.progressDetails, [data.fieldName]: data.value },
      });
    }
    if (key === 'activity') {
      dispatch({
        type: ACTIVITY_CALENDAR,
        payload: { ...filterValues.ActivityDetails, [data.fieldName]: data.value },
        dashboardType: 'national',
      });
      setFilterValues({
        ...filterValues,
        ActivityDetails: { ...filterValues.ActivityDetails, [data.fieldName]: data.value },
      });
    }
    if (key === 'activityCalendarClick') {
      dispatch({
        type: ACTIVITY_CALENDAR_TABLE,
        payload: { ...filterValues.ActivityDetails, ...filterValues.ActivityTableDetails, ...data },
        dashboardType: 'national',
      });
    }
    if (key === 'activityTable') {
      dispatch({
        type: ACTIVITY_CALENDAR_TABLE,
        payload: {
          ...filterValues.ActivityDetails,
          ...filterValues.ActivityTableDetails,
          [data.fieldName]: data.value,
        },
        dashboardType: 'national',
      });
      setFilterValues({
        ...filterValues,
        ActivityTableDetails: {
          ...filterValues.ActivityDetails,
          ...filterValues.ActivityTableDetails,
          [data.fieldName]: data.value,
        },
      });
    }
    if (key === 'mda') {
      dispatch({ type: MDA_COVERAGE, payload: { ...filterValues.MdaCoverageDetails, [data.fieldName]: data.value } });
      setFilterValues({
        ...filterValues,
        MdaCoverageDetails: { ...filterValues.MdaCoverageDetails, [data.fieldName]: data.value },
      });
    }
    if (key === 'dataQualitySort') {
      dispatch({
        type: GET_DATA_QUALITY,
        payload: {
          ...filterValues.dataQualityGraphDetails,
          ...filterValues.DataQualityDetails,
          [data.fieldName]: data.value,
        },
        dashboardType: 'national',
      });
      setFilterValues({
        ...filterValues,
        DataQualityDetails: { ...filterValues.DataQualityDetails, [data.fieldName]: data.value },
      });
    }
    if (key === 'TasSummery') {
      dispatch({ type: GET_TAS_SUMMERY, payload: { ...filterValues.tasSummeryDetails, [data.fieldName]: data.value } });
      setFilterValues({
        ...filterValues,
        tasSummeryDetails: { ...filterValues.tasSummeryDetails, [data.fieldName]: data.value },
      });
    }
    if (key === 'tasMatrix') {
      dispatch({
        type: GET_DISTRICT_MOVEDTO,
        payload: { ...filterValues.TasMatrixDetails, [data.fieldName]: data.value },
        dashboardType: 'national',
      });
      dispatch({
        type: GET_TAS_DECISION_MATRIX,
        payload: { ...filterValues.TasMatrixDetails, [data.fieldName]: data.value },
        dashboardType: 'national',
      });
      setFilterValues({
        ...filterValues,
        TasMatrixDetails: { ...filterValues.TasMatrixDetails, [data.fieldName]: data.value },
      });
    }
    if (key === 'preTasOverview') {
      dispatch({
        type: PRETAS_OVERVIEW,
        payload: { ...filterValues.PreTasOverviewDetails, [data.fieldName]: data.value },
      });
      setFilterValues({
        ...filterValues,
        PreTasOverviewDetails: { ...filterValues.PreTasOverviewDetails, [data.fieldName]: data.value },
      });
    }
    if (key === 'dataQuality') {
      dispatch({
        type: GET_DATA_QUALITY_GRAPH,
        payload: { ...filterValues.dataQualityGraphDetails, [data.fieldName]: data.value },
        dashboardType: 'national',
      });
      dispatch({
        type: GET_DATA_QUALITY,
        payload: {
          ...filterValues.dataQualityGraphDetails,
          ...filterValues.DataQualityDetails,
          [data.fieldName]: data.value,
        },
        dashboardType: 'national',
      });
      setFilterValues({
        ...filterValues,
        dataQualityGraphDetails: { ...filterValues.dataQualityGraphDetails, [data.fieldName]: data.value },
      });
    }
    if (key === 'nationalProfileSort') {
      dispatch({
        type: COUNTRY_PROFILE_TABLE,
        payload: { ...filterValues.NationalProfileSort, ...filterValues.nationalDetails, [data.fieldName]: data.value },
      });
      setFilterValues({
        ...filterValues,
        NationalProfileSort: { ...filterValues.NationalProfileSort, [data.fieldName]: data.value },
      });
    }
    if (key === 'progressSort') {
      dispatch({
        type: NATIONAL_PROGRESS_STATUS,
        payload: { ...filterValues.ProgressSortType, ...filterValues.progressDetails, [data.fieldName]: data.value },
      });
      setFilterValues({
        ...filterValues,
        ProgressSortType: { ...filterValues.ProgressSortType, [data.fieldName]: data.value },
      });
    }
    if (key === 'cards') {
      setFilterValues({
        nationalDetails: { ...filterValues.nationalDetails, [data.fieldName]: data.value },
        progressDetails: { ...filterValues.progressDetails, [data.fieldName]: data.value },
        tasSummeryDetails: { ...filterValues.tasSummeryDetails, [data.fieldName]: data.value },
        ActivityDetails: { ...filterValues.ActivityDetails, [data.fieldName]: data.value },
        MdaCoverageDetails: { ...filterValues.MdaCoverageDetails, [data.fieldName]: data.value },
        TasMatrixDetails: { ...filterValues.TasMatrixDetails, [data.fieldName]: data.value },
        PreTasOverviewDetails: { ...filterValues.PreTasOverviewDetails, [data.fieldName]: data.value },
        dataQualityGraphDetails: { ...filterValues.dataQualityGraphDetails, [data.fieldName]: data.value },
      });

      dispatch({
        type: NATIONAL_STATES_DASHBOARD_CARD,
        payload: { ...globalFilter, [data.fieldName]: data.value },
        dashboardType: 'national',
      });
      dispatch({
        type: COUNTRY_PROFILE_TABLE,
        payload: { ...filterValues.NationalProfileSort, ...filterValues.nationalDetails, [data.fieldName]: data.value },
      });
      dispatch({
        type: NATIONAL_PROGRESS_STATUS,
        payload: { ...filterValues.progressDetails, [data.fieldName]: data.value },
      });
      dispatch({
        type: GET_COUNTRY_PROFILE_CHART,
        payload: { ...filterValues.nationalDetails, [data.fieldName]: data.value },
        dashboardType: 'national',
      });
      dispatch({
        type: COUNTRY_PROFILE_MAP,
        payload: { ...filterValues.nationalDetails, [data.fieldName]: data.value },
        dashboardType: 'national',
      });
      dispatch({ type: GET_TAS_SUMMERY, payload: { ...filterValues.tasSummeryDetails, [data.fieldName]: data.value } });
      dispatch({
        type: NATIONAL_PROGRESS_STATUSBAR_GRAPH,
        payload: { ...filterValues.progressDetails, [data.fieldName]: data.value },
        dashboardType: 'national',
      });
      dispatch({
        type: ACTIVITY_CALENDAR,
        payload: { ...filterValues.ActivityDetails, [data.fieldName]: data.value },
        dashboardType: 'national',
      });
      dispatch({ type: MDA_COVERAGE, payload: { ...filterValues.MdaCoverageDetails, [data.fieldName]: data.value } });
      // tas decision matrix
      dispatch({
        type: GET_DISTRICT_MOVEDTO,
        payload: { ...filterValues.TasMatrixDetails, [data.fieldName]: data.value },
        dashboardType: 'national',
      });
      dispatch({
        type: GET_TAS_DECISION_MATRIX,
        payload: { ...filterValues.TasMatrixDetails, [data.fieldName]: data.value },
        dashboardType: 'national',
      });
      // pre-tas overview
      dispatch({
        type: PRETAS_OVERVIEW,
        payload: { ...filterValues.PreTasOverviewDetails, [data.fieldName]: data.value },
      });
      // data quality
      dispatch({
        type: GET_DATA_QUALITY_GRAPH,
        payload: { ...filterValues.dataQualityGraphDetails, [data.fieldName]: data.value },
        dashboardType: 'national',
      });
      dispatch({
        type: GET_DATA_QUALITY,
        payload: {
          ...filterValues.dataQualityGraphDetails,
          ...filterValues.DataQualityDetails,
          [data.fieldName]: data.value,
        },
        dashboardType: 'national',
      });
      setGlobalFilter({ ...globalFilter, [data.fieldName]: data.value });
    }
  };

  React.useMemo(() => {
    if (tasDecisionMatrix?.national && tasDecisionMatrix?.national.length > 0) {
      const tasDecisionMatrixTooltip = tasDecisionMatrix?.national.map((data) => {
        return {
          ...data,
          site_names:
            data?.site_names !== 'NA' ? (
              <Tooltip
                title={<TooltipTitle contentTitle="Block Names" districts={data?.site_names?.split(',')} />}
                placement="right"
                className="toolHeader"
              >
                <div>{data?.site_names?.split(',').length}</div>
              </Tooltip>
            ) : (
              data?.site_names
            ),
        };
      });
      setTasDecisionMatrixData(tasDecisionMatrixTooltip);
    } else {
      setTasDecisionMatrixData(tasDecisionMatrix?.national);
    }
  }, [tasDecisionMatrix?.national]);

  useEffect(() => {
    const fetchData = async () => {
      const url = 'https://outpost.mappls.com/api/security/oauth/token';
      const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
      };

      const data = new URLSearchParams();
      data.append('grant_type', 'client_credentials');
      data.append(
        'client_id',
        '33OkryzDZsKu_9QAwWqMx1uTNVkRDhd1TIDHT-hFQMbsDLjrMHOED3XVaX066LhTDoj_w75hvyRXRg3FGB6liw==',
      );
      data.append(
        'client_secret',
        'lrFxI-iSEg_i4UCfSGyBKhJaIPayfTu2TNK2lOsbrDYCcsWJ3RfC9oeLamRAESCETWdAZtKH8bj8XcK_HaHAez2c1HgqWR53',
      );

      try {
        const response = await axios.post(url, data, headers);
        console.log(response?.data, 'response');

        setToken(response?.data?.access_token);
        console.log(token, 'token');
        // alert('try');
      } catch (error) {
        console.error('Error:', error);
        // alert('catch');
      }
    };

    fetchData();
  }, []);

  console.log(token, 'Accesstoken');

  /**
   * @param {number} stateId selected state id
   * @param {string} key selected state id
   */
  const getDistrictList = (stateId, key) => {
    dispatch({ type: GET_DISTRICT_DROPDOWN, payload: { state: stateId } });
    districtList.selectedKey = key;
  };
  if (loading) return <Loader />;
  return (
    <Grid style={{ display: showDisplay ? '' : 'none' }} className="display_scroll">
      {/* <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop> */}
      <Grid item xs={12} className="title-national-dash" direction="row" justifyContent="space-between" container>
        <Grid item xs={7}>
          <Typography text="National Dashboard" type="subHeading" customStyle="" />
        </Grid>
        <Grid item xs={5} sm={12} direction="row" justifyContent="flex-end" className="fileGerator" container>
          <FileGenerator
            DropdownList={['Year', 'State', 'Strategy']}
            DropdownSelectedValues={globalFilter}
            showExporter={false}
            // districtDropdown={districtList.cards}
            getDistrictList={(stateId) => getDistrictList(stateId, 'cards')}
            getSelectValue={(data) => getSelectValue(data, 'cards')}
          />
          <label className="Note">Note: Block strategy has been initiated from 2022</label>
        </Grid>
      </Grid>
      <Grid container className="GridView">
        {nationalStateDashboardCard?.national.map((item, index) => {
          const key = index;
          return (
            <Grid item xs={12} md={3} sm={4} className="gridWidth">
              <DashboardCard
                key={key}
                firstValue={item.count}
                firstContent={item.name}
                secondValue={item.population_at_risk}
                secondContent={item.type}
                cardTitle={globalFilter.Year}
                backgroundColor={item.type}
                districtContent={item.Districts_Name}
                districtValue={item.Districts_Count}
                districtPassContent={item.Districts_pass_Name}
                districtPassValue={item.Districts_pass_Count}
                districtFailContent={item.Districts_fail_Name}
                districtFailValue={item.Districts_fail_Count}
                districtPendingContent={item.Districts_pending_Name}
                districtPendingValue={item.Districts_pending_Count}
                NationalDashboard
              />
            </Grid>
          );
        })}
      </Grid>
      <Grid item md={12} xs={12} sm={12} className="section-bar">
        <DynChartBlock
          title="Country Profile"
          labelText="LF Endemicity Map"
          progressLine={12}
          tableContent={countryProfileTable}
          tableHeader={countryHeader}
          tableSortList={['Sort']}
          getTableSelect={(data) => getSelectValue(data, 'nationalProfileSort')}
          getSelectValue={(data) => getSelectValue(data, 'map')}
          DropdownList={['Year', 'State', 'Strategy', 'Country Profile Activity', 'Country Profile Status']}
          ChartDropdownSelectedValues={filterValues.nationalDetails}
          TableDropdownSelectedValues={filterValues.NationalProfileSort}
          chartDatas={getCountryProfileChart?.national}
          // districtDropdown={districtList.countryProfile}
          // getDistrictList={(stateId) => getDistrictList(stateId, 'countryProfile')}
          progressChartType="national"
          getProgressLegends={getProgressLegends}
        >
          {/* <DistrictMap
            getMapLegends={getMapLegends}
            activityStatusArr={countryProfileMap?.national}
            individualState={null}
          /> */}
          {/* <MapComponent
            selectedState={checkstates}
            stateCoordinates={stateCoordinates}
            token={token}
            selectedStrategy={selectedStrategy}
            countryProfileMap={
              countryProfileMap
              //countryProfileMap.length <= 1 && countryProfileMap.length >= 200 ? countryProfileMap : []
            }
          /> */}
          <MapComponent
            selectedState={
              checkstates !== undefined && checkstates !== null && checkstates !== '' ? checkstates : 'Odisha'
            }
            stateCoordinates={stateCoordinates}
            token={token}
            selectedStateYear={selectedStateYear}
            selectedDistrict={selectedDistrict}
            selectedStrategy={selectedStrategy}
            countryProfileMap={countryProfileMap}
          />
        </DynChartBlock>
      </Grid>

      <Grid item md={12} xs={12} sm={12} className="Activity calendar">
        <HeaderSection label="Activity Calendar" />
        <ActivityCalendar
          tableSortList={['Sort', 'Activity']}
          tableContent={activityCalendarTable?.national}
          tableHeader={activityTableHead}
          legends={activityLegends?.calendarLegends}
          calendarHeader={activityHeader}
          calendarData={activityCalendar?.national}
          getSelectValue={(data, ele) => getSelectValue(data, ele)}
          onCalenderClick={(data, ele) => getSelectValue(data, ele)}
          getTableSelectValue={(data, ele) => getSelectValue(data, ele)}
          DropdownList={['Year', 'Month', 'State', 'Activity', 'Calendar Status', 'Strategy']}
          ChartDropdownSelectedValues={filterValues.ActivityDetails}
          TableDropdownSelectedValues={filterValues.ActivityTableDetails}
          // districtDropdown={districtList.activity}
          // getDistrictList={(stateId) => getDistrictList(stateId, 'activity')}
        />
      </Grid>

      <Grid item md={12} xs={12} sm={12} className="Progress Status">
        <DynChartBlock
          title="Progress Status"
          tableContent={progressStatus}
          tableHeader={progressHeader}
          tableSortList={['Sort']}
          getSelectValue={(data) => getSelectValue(data, 'progress')}
          getTableSelect={(data) => getSelectValue(data, 'progressSort')}
          DropdownList={['Year', 'State', 'Activity', 'Status', 'Strategy']}
          ChartDropdownSelectedValues={filterValues.progressDetails}
          TableDropdownSelectedValues={filterValues.ProgressSortType}
          // districtDropdown={districtList.progressStatus}
          // getDistrictList={(stateId) => getDistrictList(stateId, 'progressStatus')}
          hideFields={['activityPlaned']}
          Disclaimer
        >
          <StackedBarLineChart
            BarKeys={[
              { label: 'Completed', color: '#348FDC' },
              // { label: 'Delayed', color: '#C9CAC9' },
              { label: 'Pending', color: '#FD6767' },
            ]}
            XKey="label"
            chartData={nationalStateProgressStatusbar?.national}
            XLabel="Program Activity"
            Y1Label="Status"
            BarValuePosition="center"
            Legends
            percent
            stacked
          />
        </DynChartBlock>
      </Grid>

      <Grid className="MDA Coverage" item md={12} xs={12} sm={12}>
        <CommonTitledTable
          title="MDA Profile"
          getSelectValue={(data) => getSelectValue(data, 'mda')}
          tableSortList={['Year', 'State', 'Sort', 'Activity_MDA', 'Strategy']}
          TableDropdownSelectedValues={filterValues.MdaCoverageDetails}
          tableContent={MdaCoverage}
          tableHeader={mdaHeader}
          tableType="mdaCoverage"
          // districtDropdown={districtList.mdaCoverage}
          // getDistrictList={(stateId) => getDistrictList(stateId, 'mdaCoverage')}
        />
      </Grid>

      <Grid className="Pre-TAS/TAS Overview" item md={12} xs={12} sm={12}>
        <CommonTitledTable
          title="Pre-TAS/TAS Overview"
          tableType="preTas"
          tableContent={preTasOverview}
          tableHeader={preTasDataHead}
          getSelectValue={(data) => getSelectValue(data, 'preTasOverview')}
          tableSortList={['Year', 'State', 'Status', 'Sort', 'Strategy']}
          TableDropdownSelectedValues={filterValues.PreTasOverviewDetails}
          // districtDropdown={districtList.preTas}
          // getDistrictList={(stateId) => getDistrictList(stateId, 'preTas')}
        />
      </Grid>

      <Grid className="TAS Decision Matrix" item md={12} xs={12} sm={12}>
        <CommonTitledTable
          title="TAS District Profile"
          getSelectValue={(data) => getSelectValue(data, 'tasMatrix')}
          tableSortList={['Year', 'State', 'District', 'Sort', 'Activity_PreTAS_TAS', 'Status', 'Strategy']}
          TableDropdownSelectedValues={filterValues.TasMatrixDetails}
          tableContent={tasDecisionMatrixData}
          tableHeader={decisionMatrixHeader}
          // tableType="mdaCoverage" // UAT changes
          districtDropdown={districtList.tasDecision}
          getDistrictList={(stateId) => getDistrictList(stateId, 'tasDecision')}
          hideKeyFields={['data']} // UAT changes
        />
        <Grid item md={12} xs={12} sm={12}>
          <StackedBarLineChart
            LineKeys={[{ label: 'New Endemic District', color: '#2d99c5' }]}
            BarKeys={[
              { label: 'Endemic Districts yet to reach TAS status', color: '#fd6767' },
              { label: 'TAS 1 Cleared', color: '#48e1fe' },
              { label: '', color: 'white' },
            ]}
            XKey="years"
            title="District Moved to TAS Vs Total Endemic District"
            chartData={DistrictMovedToData.national}
            XLabel="Years"
            Y1Label="District Count"
            Y2Label="New Endemic District"
            Legends
            LineValuePosition="right"
            BarValuePosition="center"
          />
        </Grid>
      </Grid>

      <Grid className="MDA Coverage" item md={12} xs={12} sm={12}>
        <CommonTitledTable
          title="TAS EU Profile"
          getSelectValue={(data) => getSelectValue(data, 'TasSummery')}
          tableSortList={['Year', 'State', 'District', 'Sort', 'Status', 'Strategy']}
          TableDropdownSelectedValues={filterValues.tasSummeryDetails}
          tableContent={getTasSummery}
          tableHeader={tasSummary}
          // tableType="mdaCoverage" // UAT changes
          districtDropdown={districtList.tasSummary}
          getDistrictList={(stateId) => getDistrictList(stateId, 'tasSummary')}
          hideKeyFields={['delayedTas']} // UAT changes
        />
      </Grid>

      <Grid item md={12} xs={12} sm={12} container direction="row" className="Data Quality">
        {/* <DynChartBlock
          title="Report Status"
          tableContent={dataQuality?.national}
          tableHeader={NationalDataQualityHeader}
          tableSortList={['Sort']}
          getTableSelect={(data) => getSelectValue(data, 'dataQualitySort')}
          getSelectValue={(data) => getSelectValue(data, 'dataQuality')}
          DropdownList={['Year', 'State', 'Activity', 'Strategy']}
          ChartDropdownSelectedValues={filterValues.dataQualityGraphDetails}
          TableDropdownSelectedValues={filterValues.DataQualityDetails}
          // districtDropdown={districtList.dataQuality}
          // getDistrictList={(stateId) => getDistrictList(stateId, 'dataQuality')}
        > */}
        {/* <GroupBarChart barData={dataQualityGraph?.national} /> */}
        {/* </DynChartBlock> */}
      </Grid>
    </Grid>
  );
};

NationalDashboard.propTypes = {
  showDisplay: PropTypes.bool.isRequired,
};
export default NationalDashboard;